import { SelectOption } from './global';
import { getStepFormData as localStorageFormData } from 'utils/localStorageSession';
/* eslint-disable no-undef */
import isUndefined from 'lodash-es/isUndefined';

const getLocalStorageData = (
  formStep: string,
  attr: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any
) => {
  const stepStoredData = localStorageFormData(formStep) || [];
  const value = stepStoredData[attr];
  return !isUndefined(value) ? value : defaultValue;
};

export interface UTMInformation {
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export const utmInformationValueDefault: UTMInformation = {
  utm_campaign: '',
  utm_content: '',
  utm_medium: '',
  utm_source: '',
  utm_term: ''
};

export interface ContactInformation {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  email_address: string;
  birth_month: string;
  birth_day: string;
  birth_year: string;
  agent_code?: string;
  born_on?: string;
  business_relationship: string;
  business_role?: string;
  identifications?: File[];
  work_number?: string;
}

export const contactInformationDefault: ContactInformation = {
  first_name: getLocalStorageData('step_3', 'first_name', ''),
  middle_name: getLocalStorageData('step_3', 'middle_name', ''),
  last_name: getLocalStorageData('step_3', 'last_name', ''),
  birth_day: getLocalStorageData('step_3', 'birth_day', ''),
  birth_month: getLocalStorageData('step_3', 'birth_month', ''),
  birth_year: getLocalStorageData('step_3', 'birth_year', ''),
  mobile_number: getLocalStorageData('step_1', 'mobile_number', ''),
  email_address: getLocalStorageData('step_1', 'email_address', ''),
  business_relationship: getLocalStorageData(
    'step_3',
    'business_relationship',
    ''
  ),
  work_number: getLocalStorageData('step_3', 'work_number', '')
};

export const businessRegistrationInformationValueDefault: BusinessRegistrationInformation = {
  business_name: getLocalStorageData('step_1', 'name', ''),
  business_reg_num: getLocalStorageData('step_1', 'reg_num', ''),
  business_type: getLocalStorageData('step_1', 'business_type', ''),
  region_of_operation: getLocalStorageData('step_1', 'primary_region', ''),
  region_of_registration: getLocalStorageData('step_1', 'region', ''),
  email_address: getLocalStorageData('step_1', 'email_address', ''),
  mobile_number: getLocalStorageData('step_1', 'mobile_number', '')
};

export interface BusinessRegistrationInformation {
  business_name: string;
  business_reg_num: string;
  business_type: string;
  region_of_operation: string;
  region_of_registration: string;
  agent_code?: string;
  email_address: string;
  mobile_number: string;
}

export interface FinancingNeed {
  financing_amount: string;
  financing_period: string;
}

export const financingNeedValueDefault: FinancingNeed = {
  financing_amount: getLocalStorageData('step_2', 'financing_amount', ''),
  financing_period: getLocalStorageData('step_2', 'financing_period', '')
};

export interface BusinessActivity {
  industry: string;
  monthly_revenue: string;
  clients: string[];
  afs?: File[];
  gis?: File[];
  itr?: File[];
  files?: File[];
}

export const businessActivityValueDefault: BusinessActivity = {
  industry: getLocalStorageData('step_2', 'industry', ''),
  monthly_revenue: getLocalStorageData('step_2', 'monthly_revenue', ''),
  clients: getLocalStorageData('step_2', 'clients', [])
};

export interface EndInformation {
  preferred_calltime: string;
}

export const endInformationValueDefault: EndInformation = {
  preferred_calltime: getLocalStorageData('step_5', 'preferred_calltime', '')
};

export const emptyOption: SelectOption = {
  label: 'Select',
  value: ''
};

export const amountOptions: SelectOption[] = [
  emptyOption,
  { label: '₱ 100,000 or less', value: 'less_than_100k' },
  { label: '₱ 100,001 to ₱ 300,000', value: '100k_to_300k' },
  { label: '₱ 300,001 to ₱ 600,000', value: '300k_to_600k' },
  { label: '₱ 600,001 to ₱ 1,200,000', value: '600k_to_1.2M' },
  { label: '₱ 1,200,001 to ₱ 2,500,000', value: '1.2M_to_2.5M' },
  { label: '₱ 2,500,001 to ₱ 5,000,000', value: '2.5M_to_5M' },
  { label: '₱ 5,000,001 to ₱ 7,500,000', value: '5M_to_7.5M' },
  { label: '₱ 7,500,001 to ₱ 10,000,000', value: '7.5M_to_10M' },
  { label: 'More than ₱ 10,000,000', value: 'more_than_10M' }
];

export const periodOptions: SelectOption[] = [
  emptyOption,
  { label: 'Immediately', value: 'immediately' },
  { label: 'In 1 month', value: 'a_month' },
  { label: 'In 2-3 months from now', value: 'more_than_a_month' },
  { label: 'Just preparing for the future', value: 'future' }
];

// Don't remove this lines for now.

// Automobiles & Components
// Banks
// Commercial & Professional Services
// Construction
// Consumer Durables & Apparel
// Consumer Services
// Diversified Financials
// Energy
// Food & Staples Retailing
// Food, Beverage & Tobacco
// Healthcare equipment
// Household & Personal Products
// Installation
// Insurance
// Manufacturing
// Materials
// Media
// Pharmaceuticals, Biotechnology & Life Sciences
// Real Estate
// Semiconductors & Semiconductor Equipment
// Services
// Software & Services
// Specialized Construction
// Technology Hardware & Equipment
// Telecommunication Services
// Trading
// Transportation

export const industries: SelectOption[] = [
  emptyOption,
  {
    label: 'Accommodation & Hospitality',
    value: 'Accommodation & Hospitality'
  },
  { label: 'Automobiles & Components', value: 'Automobiles & Components' },
  { label: 'Banks', value: 'Banks' },
  {
    label: 'Commercial & Professional Services',
    value: 'Commercial & Professional Services'
  },
  { label: 'Construction', value: 'Construction' },
  {
    label: 'Construction & Hardware Materials',
    value: 'Construction & Hardware Materials'
  },
  {
    label: 'Consumer Durables & Apparel',
    value: 'Consumer Durables & Apparel'
  },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Diversified Financials', value: 'Diversified Financials' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Events & Entertainment', value: 'Events & Entertainment' },
  {
    label: 'Food & Staples Retailing',
    value: 'Food & Staples Retailing'
  },
  {
    label: 'Food, Beverage & Tobacco',
    value: 'Food, Beverage & Tobacco'
  },
  {
    label: 'Healthcare equipment',
    value: 'Healthcare equipment'
  },
  {
    label: 'Household & Personal Products',
    value: 'Household & Personal Products'
  },
  {
    label: 'Installation',
    value: 'Installation'
  },
  {
    label: 'Insurance',
    value: 'Insurance'
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing'
  },
  {
    label: 'Materials',
    value: 'Materials'
  },
  {
    label: 'Media',
    value: 'Media'
  },
  {
    label: 'Pharmaceuticals, Biotechnology & Life Sciences',
    value: 'Pharmaceuticals, Biotechnology & Life Sciences'
  },
  {
    label: 'Real Estate',
    value: 'Real Estate'
  },
  {
    label: 'Semiconductors & Semiconductor Equipment',
    value: 'Semiconductors & Semiconductor Equipment'
  },
  {
    label: 'Services',
    value: 'Services'
  },
  {
    label: 'Software & Services',
    value: 'Software & Services'
  },
  {
    label: 'Technology Hardware & Equipment',
    value: 'Technology Hardware & Equipment'
  },
  {
    label: 'Telecommunication Services',
    value: 'Telecommunication Services'
  },
  {
    label: 'Trading',
    value: 'Trading'
  },
  {
    label: 'Transportation',
    value: 'Transportation'
  }
];

export const industriesWithSpecializedConstruction: SelectOption[] = [
  ...industries,
  { label: 'Specialized Construction', value: 'Specialized Construction' }
];

export const monthlyRevenue: SelectOption[] = [
  emptyOption,
  { label: '₱400,000 or less', value: '₱400,000 or less' },
  { label: '₱400,001 to ₱800,000', value: '₱400,001 to ₱800,000' },
  { label: '₱800,001 to ₱1,250,000', value: '₱800,001 to ₱1,250,000' },
  { label: '₱1,250,001 to ₱2,500,000', value: '₱1,250,001 to ₱2,500,000' },
  { label: '₱2,500,001 to ₱4,000,000', value: '₱2,500,001 to ₱4,000,000' },
  { label: '₱4,000,001 to ₱16,000,000', value: '₱4,000,001 to ₱16,000,000' },
  { label: 'More than ₱16,000,000', value: 'More than ₱16,000,000' }
];

export const businessRoles: SelectOption[] = [
  emptyOption,
  { label: 'Owner / Co-Owner', value: 'Owner / Co-Owner' },
  { label: 'Executive Management', value: 'Executive Management' },
  { label: 'Finance Manager', value: 'Finance Manager' },
  { label: 'Treasurer', value: 'Treasurer' },
  { label: 'Secretary / Assistant', value: 'Secretary / Assistant' },
  { label: 'Employee', value: 'Employee' }
];

export const callTimes: SelectOption[] = [
  emptyOption,
  { label: '9:00 AM to 12:00 NN', value: '9:00 AM to 12:00 NN' },
  { label: '1:00 PM to 3:30 PM', value: '1:00 PM to 3:30 PM' },
  { label: '3:30 PM to 6:00 PM', value: '3:30 PM to 6:00 PM' }
];

export interface ZendeskEvent {
  category: string;
  action: string;
  properties: object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFileArray(value: any): value is File[] {
  return Array.isArray(value) && value.every(item => item instanceof File);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBusinessActivity(obj: any): obj is BusinessActivity {
  return (
    typeof obj.industry === 'string' &&
    typeof obj.monthly_revenue === 'string' &&
    (obj.afs === undefined || isFileArray(obj.afs)) &&
    (obj.gis === undefined || isFileArray(obj.gis)) &&
    (obj.itr === undefined || isFileArray(obj.itr))
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isContactInformation(obj: any): obj is ContactInformation {
  return (
    typeof obj.first_name === 'string' &&
    typeof obj.middle_name === 'string' &&
    typeof obj.last_name === 'string' &&
    typeof obj.mobile_number === 'string' &&
    typeof obj.email_address === 'string' &&
    typeof obj.birth_month === 'string' &&
    typeof obj.birth_day === 'string' &&
    typeof obj.birth_year === 'string' &&
    (obj.agent_code === undefined || typeof obj.agent_code === 'string') &&
    (obj.born_on === undefined || typeof obj.born_on === 'string') &&
    (obj.business_relationship === undefined ||
      typeof obj.business_relationship === 'string') &&
    (obj.identifications === undefined || isFileArray(obj.identifications))
  );
}
