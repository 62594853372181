import React from 'react';
import { css } from 'aphrodite';

import GlobalStyles from 'components/common/styles/GlobalStyles';

interface Props {
  label: string;
  dataTestId: string;
  action: () => void;
  className?: string;
}

const LinkButton: React.FC<Props> = ({
  label,
  dataTestId,
  action,
  className
}) => {
  return (
    <button
      className={`btn btn-link ${css(GlobalStyles.noPadding)} ${className}`}
      onClick={() => action()}
      data-testid={dataTestId}
    >
      {label}
    </button>
  );
};

export default LinkButton;
