/* eslint-disable no-undef */
export enum KEYS {
  STEPS = 'obSteps',
  OBID = 'obID'
}

const filteredSteps = (actualStep: string) => {
  //NOTE: Remove if current step is existing on the local storage to replace with
  //a new one
  let filtered;
  const steps = JSON.parse(localStorage.getItem(KEYS.STEPS) || '[]');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isExisting = steps.some((step: any) => step.step === actualStep);

  if (isExisting) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filtered = steps.filter((step: any) => step.step !== actualStep);
    return filtered;
  }
  return steps;
};

export const saveStep = (params: object, step: string) => {
  const arraySteps = filteredSteps(step);
  arraySteps.push({ ...params, step: step });
  localStorage.setItem(KEYS.STEPS, JSON.stringify(arraySteps));
};

export const saveUID = (uid: string) => {
  localStorage.setItem(KEYS.OBID, uid);
};

export const getStepFormData = (formStep: string) => {
  let stepFormData = JSON.parse(localStorage.getItem(KEYS.STEPS) || '[]');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepFormData = stepFormData.filter((step: any) => step.step === formStep);
  //NOTE: get the last
  return stepFormData.slice(-1)[0];
};

export const getObID = () => {
  const savedObID = localStorage.getItem(KEYS.OBID);

  if (savedObID) {
    return savedObID;
  }
};
