/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, isNil } from 'lodash-es';
import { css } from 'aphrodite';
import OtpInput from 'react-otp-input';
import useTimer from 'hooks/useTimer';
import SimpleLoader from 'components/common/loaders/SimpleLoader';
import LinkButton from 'components/common/buttons/LinkButton';
import ApplicationContext from 'contexts/ApplicationContext';
import useRequestOtp from 'hooks/onboarding/useRequestOtp';
import { showNotification } from 'utils/toast';
import useValidateOtp from 'hooks/onboarding/useValidateOtp';
import { navigateTo } from 'utils/navigation';
import { maskLocalMobileNumber } from 'utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './OtpStyles';

const REQUIRED_OTP_LENGTH = 6;
const MAX_INVALID_COUNT = 2;

const Otp: React.FC = () => {
  const [otp, setOtp] = useState<string | undefined>(undefined);
  const [invalidOtp, setInvalidOtp] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [invalidCount, setInvalidCount] = useState<number>(0);

  const { contactInformationValue, setBackEnabled } = useContext(
    ApplicationContext
  );
  const { countDown, startTimer, stopTimer, running } = useTimer(60);

  function onRequestSuccess(): void {
    showNotification('success', 'OTP sent!');
  }

  function onRequestFailed(): void {
    showNotification('error', 'OTP request failed!');
    stopTimer();
  }

  function onValidateSuccess(): void {
    setBackEnabled(false);
    navigateTo('/pre-qualification-form/confirmation');
  }

  function onValidateFailed(): void {
    if (invalidCount > MAX_INVALID_COUNT) {
      navigateTo('/pre-qualification-form/step-3');
    }
    setInvalidOtp(true);
    setValidating(false);
    setInvalidCount(invalidCount + 1);
  }

  const {
    requestOtp,
    isLoading: requestingOtp,
    error: requestError
  } = useRequestOtp({
    onSuccessCallback: onRequestSuccess,
    onFailCallback: onRequestFailed
  });

  const {
    validateOtp,
    isLoading: validatingOtp,
    error: validateError
  } = useValidateOtp({
    onSuccessCallback: onValidateSuccess,
    onFailCallback: onValidateFailed
  });

  // This is to avoid referencing setter
  // functions in useEffect call below
  // We don't want to monitor reference
  // changes in methods or functions
  const onMount = () => {
    startTimer();
    requestOtp();
  };

  useEffect(onMount, []);

  useEffect(() => {
    if (countDown <= 0) {
      stopTimer();
    }
  }, [countDown, stopTimer]);

  function validateCode() {
    if (validatingOtp) return;

    if (!isNil(otp) && otp.length === REQUIRED_OTP_LENGTH) {
      setValidating(true);
      setInvalidOtp(false);
      validateOtp(otp);
    }
  }

  useEffect(validateCode, [otp]);

  function handleChange(otp: string) {
    setOtp(otp);
  }

  function resendCode() {
    if (requestingOtp || validatingOtp) return;
    startTimer();
    setOtp(undefined);
    setInvalidOtp(false);
    requestOtp();
  }

  function renderError() {
    let errorMessage: null | string = null;

    if (!isNil(requestError)) {
      errorMessage =
        'Sorry, we are unable to generate a code for you, please try again';
    }

    if (!isNil(validateError) && invalidOtp) {
      errorMessage =
        'Sorry, we are unable to validate your code, please try again';
    }

    if (invalidOtp) {
      errorMessage = 'Invalid OTP';
    }

    if (isNil(errorMessage)) return null;

    return (
      <div
        data-testid="otp-errors-container"
        className={`${css(styles.errorContainer)}`}
      >
        {errorMessage}
      </div>
    );
  }

  if (requestingOtp) {
    return <SimpleLoader message="Generating OTP..." color="#565EF0" />;
  }

  if (validating) {
    return <SimpleLoader message="Verifying OTP..." color="#565EF0" />;
  }

  return (
    <div
      className={`_marginTop--large _marginBottom--medium ${css(
        styles.container
      )}`}
      data-testid="otp"
    >
      <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--xsmall">
        <p className={`_marginBottom--xsmall ${css(styles.otpGuide)}`}>
          Enter the 6-digit code that was sent to
        </p>
        <p className={`${css(styles.otpGuide)}`}>
          your mobile number{' '}
          {maskLocalMobileNumber(contactInformationValue?.mobile_number || '')}
        </p>
      </div>
      <div
        className={`_horizontalMargin--xxxlarge-tabletUp _marginTop--large`}
        style={{ display: 'flex' }}
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          renderSeparator={<div />}
          inputStyle={`${css(styles.otpInput)} 
            ${
              invalidOtp
                ? `${css(styles.errorInputBox)}`
                : `${css(styles.normalInputBox)}`
            }`}
          renderInput={props => <input {...props} />}
        />

        {invalidOtp && !isNil(otp) && !isEmpty(otp) && (
          <div
            data-testid="otp-clear-button"
            className={`${css(styles.clearIcon)}`}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="sm"
              color="#da5e5e"
              onClick={() => {
                setOtp(undefined);
                setInvalidOtp(false);
              }}
            />
          </div>
        )}
      </div>

      {renderError()}

      <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--large">
        <span>Didn't receive your code? </span>
        {running ? (
          <span style={{ color: '#565EF0' }}>
            Resend OTP in {countDown} {countDown > 1 ? 'seconds' : 'second'}
          </span>
        ) : (
          <LinkButton
            label="Resend OTP"
            action={resendCode}
            dataTestId="resend-otp-button"
            className={`${css(styles.resendOtp)}`}
          />
        )}
      </div>
    </div>
  );
};

export default Otp;
