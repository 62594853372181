import isNil from 'lodash/isNil';
import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';

import useFetch from '../useFetch';
import ApplicationContext from 'contexts/ApplicationContext';
import { useContext } from 'react';

interface RequestOtpReturn extends CommonReturnProps {
  requestOtp: () => void;
}

const useRequestOtp = (props?: CommonProps): RequestOtpReturn => {
  const { leadId } = useContext(ApplicationContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (): void => {
    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback();
    }
  };

  const onFailCallback = (): void => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const requestOtp = (): void => {
    executeRequest({
      path: `/generate_otp`,
      method: METHODS.POST,
      shouldExecuteRequest: true,
      payload: { lead_id: leadId }
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    requestOtp
  };
};

export default useRequestOtp;
