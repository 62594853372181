/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext } from 'react';
import { isNil, isEmpty } from 'lodash-es';
import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { isContactInformation } from 'interfaces/onboarding';
import directUpload, { GlobalBlob } from 'utils/directUpload';
import ApplicationContext from 'contexts/ApplicationContext';

interface UploadIdentificationsReturn extends CommonReturnProps {
  uploadIdentifications: (values: Record<string, any>) => void;
}

const useUploadIdentifications = (
  props?: CommonProps
): UploadIdentificationsReturn => {
  const { setContinueIsLoading } = useContext(ApplicationContext);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const onSuccessCallback = (params: any): void => {
    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback(params);
    }
  };

  const onFailCallback = (): void => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const uploadIdentifications = async (values: Record<string, any>) => {
    if (!isContactInformation(values)) return;
    setContinueIsLoading(true);

    const { identifications, ...rest } = values;

    // submit without uploaded ids
    if (isNil(identifications) || isEmpty(identifications)) {
      const params = {
        ...rest,
        born_on: `${values.birth_day}-${values.birth_month}-${values.birth_year}`
      };

      onSuccessCallback({ ...params });
      return;
    }

    try {
      setIsLoading(true);

      const signedIdentifications = await Promise.all(
        identifications.map(async (file: File) => {
          try {
            const uploadIdentification: GlobalBlob = await directUpload(file);
            return uploadIdentification.signed_id;
          } catch (uploadError) {
            setIsFailed(true);
            setError(uploadError);
            throw uploadError;
          }
        })
      );

      const params = {
        ...rest,
        born_on: `${values.birth_day}-${values.birth_month}-${values.birth_year}`,
        identifications: signedIdentifications
      };

      setIsSuccessful(true);
      setIsLoading(false);
      setIsFailed(false);
      onSuccessCallback({ ...params });
    } catch (catchError) {
      setIsFailed(true);
      setError(catchError);
      setIsLoading(false);
      onFailCallback();
    }
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    uploadIdentifications
  };
};

export default useUploadIdentifications;
