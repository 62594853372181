/* eslint-disable no-undef */
import React from 'react';

import OnboardingTemplate from 'components/templates/pageTemplate/OnboardingTemplate';
import Otp from 'components/features/onboarding/Otp';

const OtpPage: React.FC = () => {
  return (
    <OnboardingTemplate
      dataTestId="otp-page"
      hideLegal
      stepName="Verify mobile number"
      headerTitle="Verify your mobile number"
      showFooter
      hideContinue
    >
      <Otp />
    </OnboardingTemplate>
  );
};

export default OtpPage;
