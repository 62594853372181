/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { css } from 'aphrodite';
import { navigate } from '@reach/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PRESENTATION_TYPE, ZeWindow } from 'interfaces/global';
import { hasUpcomingMaintenance } from 'utils/environment';
import { navigateTo, navigateToFcSite } from 'utils/navigation';

import logo from '../../../images/logo-blue.png';
import Styles from './OnboardingTemplateStyles';
import Banner from 'components/common/banners/Banner';
import RaisedButton from 'components/common/buttons/RaisedButton';
import ApplicationContext from 'contexts/ApplicationContext';
import OnboardingHeader from 'components/features/onboarding/OnboardingHeader';
import OnboardingProgressBar from 'components/features/onboarding/OnboardingProgressBar';
import useTemplateRunner from 'hooks/useTemplateRunner';

declare let window: ZeWindow;

export interface OnboardingTemplateProps {
  children: React.ReactChild | React.ReactChild[];
  dataTestId: string;
  showFooter?: boolean;
  showProgress?: boolean;
  hideBack?: boolean;
  hideLegal?: boolean;
  hideContinue?: boolean;
  // TODO: Make this required when all the pages are done
  headerTitle?: string;
  stepName?: string;
  showHeader?: boolean;
}

const OnboardingTemplate: React.FC<OnboardingTemplateProps> = ({
  dataTestId,
  children,
  showFooter,
  showProgress = true,
  hideBack,
  hideLegal,
  hideContinue,
  headerTitle,
  stepName,
  showHeader
}) => {
  const {
    isContinueEnabled,
    isBackEnabled,
    stepNumber,
    setStepNumber,
    setSubmitCurrentForm,
    isContinueLoading,
    setQualified
  } = useContext(ApplicationContext);

  // load all that are need on initial load.
  useTemplateRunner();

  const resetFormStates = (): void => {
    setSubmitCurrentForm(false);
    setQualified(true);
  };

  useEffect(resetFormStates, []);

  function getStepNumber(): number {
    let step;
    switch (window.location.pathname) {
      case '/pre-qualification-form/step-1':
        step = 1;
        break;
      case '/pre-qualification-form/step-2':
        step = 2;
        break;
      case '/pre-qualification-form/step-3':
        step = 3;
        break;
      case '/pre-qualification-form/step-4':
        step = 4;
        break;
      case '/pre-qualification-form/confirmation':
        step = 5;
        break;
      default:
        step = 1;
    }
    return step;
  }

  function handleStepNumber(): void {
    const step = getStepNumber();
    setStepNumber(step);
  }

  // This is too handle back button in the browser
  useEffect(handleStepNumber, []);

  function renderBackButton(): React.ReactElement {
    if (!isBackEnabled || hideBack) return <div />;

    return (
      <RaisedButton
        type="secondary"
        dataTestId="go-back-button"
        action={() => navigate(-1)}
        classes="_margin--small _padding--small-mobileOnly _fontSize--xxsmall-mobileOnly _margin--small _outline"
        leftIconName="arrow_back_ios"
        dataTrack={`go_back_step${getStepNumber()}`}
      >
        Go back
      </RaisedButton>
    );
  }

  function renderHelpButton(): React.ReactElement {
    return (
      <RaisedButton
        type="primary"
        dataTestId="help-button"
        classes={`desktopTextOnly _margin--small ${css(Styles.helpButton)}`}
        action={() => {
          window.zE('webWidget', 'open');
        }}
        leftIconName="help_outline"
      >
        Help
      </RaisedButton>
    );
  }

  function renderContinueButton(): React.ReactElement | null {
    if (hideContinue) return null;
    return (
      <RaisedButton
        type="primary"
        dataTestId="continue-button"
        classes="_margin--small _padding--small-mobileOnly _fontSize--xxsmall-mobileOnly"
        action={() => {
          setQualified(true);
          setSubmitCurrentForm(true);
        }}
        disabled={!isContinueEnabled || isContinueLoading}
        isLoading={isContinueLoading}
        rightIconName="arrow_forward_ios"
        dataTrack={`continue_step${getStepNumber()}`}
      >
        Continue
      </RaisedButton>
    );
  }

  function renderFooter(): React.ReactElement | null {
    if (!showFooter) return null;
    return (
      <div
        className={`${css(Styles.navBar)} ${css(Styles.bottom)}`}
        data-testid="footer-nav"
      >
        {renderBackButton()}
        <div className={css(Styles.helpContainer)}>
          {renderHelpButton()}
          {renderContinueButton()}
        </div>
      </div>
    );
  }

  const renderLegal = () => {
    if (stepNumber === 3 && stepName === 'Contact Information') {
      return (
        <div
          className="_alignCenter _horizontalPadding--xxsmall _horizontalPadding--xlarge-tabletUp _fontSize--small-mobileOnly"
          data-testid="privacy-notice"
          style={{ marginBottom: '24px', marginTop: '36px' }}
        >
          <div className="byline">
            <div>
              First Circle respects your right to privacy. As such, all personal
              information submitted to us will be treated confidentially and
              shall be protected pursuant to our
              <a
                href="https://www.firstcircle.ph/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="privacy-policy-link"
                data-track="privacy_policy"
              >
                {' '}
                Privacy Policy
              </a>
              .
            </div>
            <br />
            <div>
              In filling out and submitting this form, you are agreeing to First
              Circle’s
            </div>
            <div>
              <a
                href="https://www.firstcircle.ph/website-terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="website-term-of-use-link"
                data-track="website_term_of_use"
              >
                Website Terms of Use
              </a>
              ,
              <a
                href="https://www.firstcircle.ph/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="website-term-of-use-link"
                data-track="website_term_of_use"
              >
                {' '}
                Product Terms and Conditions
              </a>
              , and
              <a
                href="https://www.firstcircle.ph/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="privacy-policy-link"
                data-track="privacy_policy"
              >
                {' '}
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="_alignCenter _horizontalPadding--xxsmall _horizontalPadding--xlarge-tabletUp _fontSize--small-mobileOnly"
        data-testid="legal"
        style={{ marginBottom: '24px', marginTop: '36px' }}
      >
        <div className="byline">
          <span>
            All financing applications are subject to First Circle's review.
          </span>
          <br />
          <div>
            Please study the terms of our loan offer before signing the
            Disclosure Statement and the Loan Agreement.
          </div>
        </div>
        <p className="byline">© 2021 First Circle Growth Finance Corp.</p>
        <p className="_fontSize--normal">
          SEC Company Reg. No. CS201605477 <br /> Certificate of Authority No.
          1108
        </p>
      </div>
    );
  };

  const handleBackFromStep5 = () => {
    if (window.history) {
      window.addEventListener(
        'popstate',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _ => {
          const step = getStepNumber();
          if (step === 4 && stepNumber === 4) {
            setStepNumber(1);
            navigateTo('/pre-qualification-form/step-1');
          }
        },
        { once: true }
      );
    }
  };

  useEffect(handleBackFromStep5, [stepNumber]);

  return (
    <>
      <ToastContainer
        bodyClassName="_padding--normal"
        hideProgressBar
        pauseOnHover
      />
      {hasUpcomingMaintenance() && (
        <Banner
          type={PRESENTATION_TYPE.WARNING}
          header={process.env.REACT_APP_MAINTENANCE_HEADER}
          title={process.env.REACT_APP_MAINTENANCE_TITLE}
          message={process.env.REACT_APP_MAINTENANCE_MSG}
        />
      )}
      <div
        className={`ds-container ${css(Styles.container)}`}
        data-testid={dataTestId}
      >
        <div
          className={`${css(Styles.navBar, Styles.top)}`}
          data-testid="header-nav"
        >
          <div className={`_margin--small ${css(Styles.navBarLogoContainer)}`}>
            <img src={logo} alt="FC logo" className={css(Styles.navBarLogo)} />
          </div>
          <div className="_displayFlex _margin--small">
            <RaisedButton
              type="secondary"
              dataTestId="go-back-btn"
              classes="_outline _padding--small-mobileOnly _fontSize--xxsmall-mobileOnly"
              action={navigateToFcSite}
              dataTrack="back_to_homepage"
            >
              Back to homepage
            </RaisedButton>
          </div>
        </div>

        <div
          className={`grid _verCenterItems-fromTableView ${css(Styles.middle)}`}
        >
          <div className="_marginTop--large">
            {showHeader && <OnboardingHeader showHeader showLogin />}
            <div className="ds-card _padding--normal">
              {!isContinueLoading && showProgress && (
                <div data-testid="header-progress">
                  <OnboardingProgressBar stepName={stepName} />
                  <h1 className="_alignCenter _marginTop--large">
                    <span
                      className={`ds-status _marginRight--small ${css(
                        Styles.stepNumberBadge
                      )}`}
                    >
                      {stepNumber}
                    </span>
                    <span>{headerTitle}</span>
                  </h1>
                </div>
              )}
              {children}
            </div>
            {!hideLegal && renderLegal()}
          </div>
        </div>
        {renderFooter()}
      </div>
    </>
  );
};

export default OnboardingTemplate;
