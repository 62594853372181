import { useContext } from 'react';
import { isNil } from 'lodash-es';

import { METHODS } from 'utils/api';
import { CommonReturnProps } from 'interfaces/apiFetch';
import { navigateToFcc } from 'utils/navigation';

import useFetch from '../useFetch';
import ApplicationContext from 'contexts/ApplicationContext';
import { USER_TYPE } from 'interfaces/user';

interface ValidateAccessTokenReturn extends CommonReturnProps {
  validateAccessToken: () => void;
}

const useValidateCapitalAdvisorToken = (): ValidateAccessTokenReturn => {
  const { setUserType, setInvalidAccess } = useContext(ApplicationContext);

  const onSuccessCallback = () => {
    setUserType(USER_TYPE.CAPITAL_ADVISOR);
    return null;
  };

  const onFailCallback = (): void => {
    if (!isNil(error) && error.status === 403) {
      setInvalidAccess(true);
      navigateToFcc();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const validateAccessToken = () => {
    executeRequest({
      path: '/capital_advisors',
      method: METHODS.GET,
      shouldExecuteRequest: true
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    validateAccessToken
  };
};

export default useValidateCapitalAdvisorToken;
