import isNil from 'lodash/isNil';

import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { useContext } from 'react';
import { METHODS } from 'utils/api';
import { saveStep } from 'utils/localStorageSession';
import { ContactInformation } from 'interfaces/onboarding';

import useFetch from '../useFetch';
import ApplicationContext from 'contexts/ApplicationContext';

interface SubmitContactInfoReturn extends CommonReturnProps {
  submitContactInfo: (params: Partial<ContactInformation>) => void;
}

const useSubmitContactInfo = (props?: CommonProps): SubmitContactInfoReturn => {
  const {
    leadId,
    setContactInformationValue,
    setContinueIsLoading,
    resetContinueState
  } = useContext(ApplicationContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (): void => {
    resetContinueState();

    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback();
    }
  };

  const onFailCallback = (): void => {
    resetContinueState();

    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const submitContactInfo = (params: Partial<ContactInformation>) => {
    saveStep(params, 'step_3');
    setContactInformationValue(params);
    setContinueIsLoading(true);
    executeRequest({
      path: `/leads/${leadId}`,
      method: METHODS.PATCH,
      shouldExecuteRequest: true,
      payload: { ...params, step: 'step_3' }
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    submitContactInfo
  };
};

export default useSubmitContactInfo;
