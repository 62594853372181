/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { CommonAttributeProps, requiredStringField } from './common/fields';
import { ServiceableRegions } from '../enums/region';
import { emailAddressField, mobileNumberField } from './contactInfo';

const regionOfOperationField = ({ attribute }: CommonAttributeProps) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .when(
      'region_of_registration',
      (region_of_registration: string, schema: yup.StringSchema) => {
        if (
          region_of_registration !== '' &&
          !ServiceableRegions.includes(region_of_registration)
        ) {
          return schema.required();
        }
        return schema;
      }
    )
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    business_name: requiredStringField({ attribute: 'Business Name' }),
    business_type: requiredStringField({ attribute: 'Business Type' }),
    is_registered_w_operating_revenue: requiredStringField({
      attribute: 'Registered and operating for more than 6 months with revenue'
    }),
    region_of_registration: requiredStringField({
      attribute: 'Region of Registration'
    }),
    region_of_operation: regionOfOperationField({
      attribute: 'Region of Operation'
    }),
    email_address: emailAddressField({ attribute: 'Email Address' }),
    mobile_number: mobileNumberField({ attribute: 'Mobile Number' })
  };
  return yup.object().shape(omitBy(shape, isNil));
};
