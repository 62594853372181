/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { css } from 'aphrodite';

import Styles from 'components/templates/pageTemplate/OnboardingTemplateStyles';
import check from '../../../assets/images/check_vector.png';
import RaisedButton from 'components/common/buttons/RaisedButton';
import { navigateToFcSite } from 'utils/navigation';

const Confirmation: React.FC = () => {
  return (
    <div data-testid="confirmation">
      <div className="_alignCenter _marginTop--normal">
        <img
          src={check}
          className={`${css(Styles.check)}`}
          alt="Check mark vector"
        />
      </div>

      <h2
        className={`headline _alignCenter -darkBlue _horizontalMargin--large-tabletUp 
          _marginTop--normal ${css(Styles.congratulations)}`}
      >
        Thank you!
      </h2>

      <h1
        className={`headline _alignCenter _verticalMargin--xlarge ${css(
          Styles.majesticMargin
        )}`}
      >
        We will get in touch with you soon!
      </h1>

      <div className={`_marginTop--normal ${css(Styles.majesticMargin)}`}>
        <RaisedButton
          type="primary"
          action={navigateToFcSite}
          fullWidth
          dataTestId="confirmation-button"
          dataTrack="confirmation"
        >
          Back to homepage
        </RaisedButton>
      </div>
    </div>
  );
};

export default Confirmation;
