import { StyleSheet } from 'aphrodite';

// Declare Colors
export const borderColor = '#E5E5E5';
export const errorColor = '#da5e5e';
export const warningColor = '#f3cf72';
export const successColor = '#0ec84f';
export const primaryColor = '#565ef0';
export const secondaryColor = '#e8e9ff';
export const lighterTextColor = '#79859A';
export const inputBorderColor = '#8082a9';
export const fcblue = '#003a70';
export const white = '#ffffff';

// Datepicker custom input
// Had to define this also.
// React date picker won't work
// fully if we wrap the component
// inside `<label className="ds-input" />
const datePickerCommon = {
  backgroundColor: '#fff',
  borderRadius: '6px',
  borderAllRadius: '6px',
  border: 'solid #8082a9',
  borderWidth: '2px',
  color: '#414141',
  fontSize: '0.95em',
  letterSpacing: '-0.028em',
  marginTop: '2px',
  marginBottom: '4px',
  padding: '12px',
  width: '100%'
};

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  flexOne: {
    flex: 1
  },
  flexTwo: {
    flex: 2
  },
  flexThree: {
    flex: 3
  },
  flexFour: {
    flex: 4
  },
  flexFive: {
    flex: 5
  },
  flexSix: {
    flex: 6
  },

  whiteBackground: {
    backgroundColor: '#ffffff',
    height: '100vh'
  },

  mainContainer: {
    backgroundColor: '#ffffff',
    minWidth: '1300px',
    height: '100vh'
  },

  pageTitle: {
    fontSize: '23px'
  },

  noPadding: {
    padding: 0
  },

  noMargin: {
    margin: 0
  },

  // headers

  headerWithBottomBorder: {
    borderBottom: `1px solid ${borderColor}`
  },

  // pagination

  pagination: {
    zIndex: 1000
  },

  // select

  selectWithError: {
    border: `2px solid ${errorColor}`,
    borderRadius: '8px'
  },

  // Checkbox

  checkbox: {
    fontSize: '12px',
    padding: '8px',
    width: '100%',
    justifyContent: 'flex-start'
  },

  simpleCheckbox: {
    backgroundColor: 'white',
    border: '0px',
    color: '#4d5257',
    paddingLeft: '0px'
  },

  simpleUnchecked: {
    color: '#212529'
  },

  simpleChecked: {
    color: primaryColor
  },

  // Date picker

  pickerWithoutError: {
    ...datePickerCommon,
    border: `1px solid ${borderColor}`
  },

  pickerWithError: {
    ...datePickerCommon,
    border: `solid ${errorColor}`
  },

  // Errors
  errorHeader: {
    fontSize: '40px',
    marginTop: '20px'
  },

  // Text

  lighterText: {
    color: lighterTextColor
  },

  bold: {
    fontWeight: 'bold'
  },

  fcblue: {
    color: fcblue
  },

  // Inputs

  textInput: {
    border: `2px solid ${borderColor}`
  },

  // this is for adding clearable options
  // in text inputs, this can be added also in
  // design system
  clearInput: {
    alignItems: 'center',
    borderRadius: '50%',
    bottom: '8px',
    cursor: 'pointer',
    backgroundColor: primaryColor,
    color: 'white',
    display: 'flex',
    height: '18px',
    justifyContent: 'center',
    position: 'absolute',
    right: '0',
    width: '18px',
    zIndex: 10
  },

  // TODO: add this styles in DS
  // Or look if it can provide now
  // ds-notice does not give clear presentations
  errorNotice: {
    borderRadius: '.4em',
    backgroundColor: '#f8d7da',
    border: `1px solid ${errorColor}`,
    color: errorColor
  },
  warningNotice: {
    borderRadius: '.4em',
    backgroundColor: '#fff3cd',
    border: `1px solid ${warningColor}`,
    color: warningColor
  },
  successNotice: {
    borderRadius: '.4em',
    backgroundColor: '#d4edda',
    border: `1px solid ${successColor}`,
    color: successColor
  },
  infoNotice: {
    borderRadius: '.4em',
    backgroundColor: secondaryColor,
    border: `1px solid ${primaryColor}`,
    color: primaryColor
  },
  noticeListItem: {
    listStyleType: 'circle'
  }
});

export default styles;
