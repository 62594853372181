import { format, differenceInCalendarDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { SelectOption } from 'interfaces/global';

export const formatDate = (date: Date, dateFormat: string) => {
  if (date) {
    return format(date, dateFormat);
  }

  return '';
};

export const loanDurationInText = (
  startDate: string,
  endDate: number
): string => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const stringStart = format(start, 'MMM dd, yyyy');
  const stringEnd = format(end, 'MMM dd, yyyy');

  return `${stringStart} - ${stringEnd}`;
};

export const loanDurationInDays = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return differenceInCalendarDays(end, start);
};

export const monthsOptions = (): SelectOption[] => {
  const months = [];
  if (enUS.localize) {
    for (let i = 0; i < 12; i++) {
      months.push({ label: enUS.localize.month(i), value: (i + 1).toString() });
    }
  }

  return [{ label: 'Select', value: '' }, ...months];
};

export const daysOptions = (): SelectOption[] => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    const day = i <= 9 ? `0${i}` : i.toString();
    days.push({ label: day, value: day });
  }

  return [{ label: 'Select', value: '' }, ...days];
};

export const yearsOptions = (): SelectOption[] => {
  const now = new Date().getUTCFullYear();
  const startYear = now - 100;
  const endYear = now - 18;

  const years = Array(endYear - startYear + 1)
    .fill('')
    .map((_, idx) => (endYear - idx).toString());

  const options = years.map((y: string) => {
    return { label: y, value: y };
  });

  return [{ label: 'Select', value: '' }, ...options];
};
