/* eslint-disable react/no-unescaped-entities */
import React, { useState, useContext, useEffect } from 'react';
import { css } from 'aphrodite';

/* eslint-disable no-undef */
import { isEmpty, isNil } from 'lodash-es';
import { buildValidationSchema } from 'validations/contactInfo';
import { showNotification } from 'utils/toast';
import {
  fcapSuccessCreationMessageInFcc,
  navigateTo,
  navigateToFcc
} from 'utils/navigation';
import { LABELS } from 'utils/labels';
import { monthsOptions, daysOptions, yearsOptions } from 'utils/date';
import { emptyOption } from 'interfaces/onboarding';

import Styles from 'components/templates/pageTemplate/OnboardingTemplateStyles';
import TextInput from 'components/common/inputs/TextInput';
import useForm from 'hooks/useForm';
import useSubmitContactInfo from 'hooks/onboarding/useSubmitContactInfo';
import ApplicationContext from 'contexts/ApplicationContext';
import DynamicSelect, { Value } from 'components/common/selects/DynamicSelect';
import enumOptions from 'utils/enum_options';
import {
  CorporationBusinessRelationships,
  SolePropBusinessRelationships,
  PartnershipBusinessRelationships,
  CorporationBusinessRelationshipsForAdvisors,
  SolePropBusinessRelationshipsForAdvisors,
  PartnershipBusinessRelationshipsForAdvisors
} from 'enums/businessRelationships';
import FileUpload from 'components/common/inputs/FileUpload';
import ModalContext from 'contexts/ModalContext';
import { ModalComponent } from 'components/modals/ModalComponents';
import useUploadIdentifications from 'hooks/onboarding/useUploadIdentifications';
import useFetchUploadedFiles, {
  ActiveStorageBlob
} from 'hooks/onboarding/useFetchUploadedFiles';
import { USER_TYPE } from 'interfaces/user';
import BezierLoader from 'components/common/loaders/BezierLoader';

const ContactInfoForm: React.FC = () => {
  const [uploadedIdentifications, setUploadedIdentifications] = useState<
    FileUpload[]
  >([]);

  const {
    userType,
    contactInformationValue,
    submitCurrentForm,
    businessRegistrationInformationValue,
    setRedirectingToFcc,
    setContinueEnabled,
    isContinueLoading
  } = useContext(ApplicationContext);

  const {
    business_type,
    business_name,
    email_address: page_1_email_address,
    mobile_number: page_1_mobile_number
  } = businessRegistrationInformationValue;

  const relationshipOptions = () => {
    let options;

    switch (business_type) {
      case 'single':
        options =
          userType === USER_TYPE.INBOUND
            ? SolePropBusinessRelationships
            : SolePropBusinessRelationshipsForAdvisors;
        break;
      case 'partnership':
        options =
          userType === USER_TYPE.INBOUND
            ? PartnershipBusinessRelationships
            : PartnershipBusinessRelationshipsForAdvisors;
        break;
      default:
        options =
          userType === USER_TYPE.INBOUND
            ? CorporationBusinessRelationships
            : CorporationBusinessRelationshipsForAdvisors;
        break;
    }

    return [emptyOption, ...enumOptions(options)];
  };

  const { setType } = useContext(ModalContext);

  function onSubmitSuccess(): void {
    if (userType === USER_TYPE.INBOUND) {
      navigateTo('/pre-qualification-form/step-4');
    } else {
      setRedirectingToFcc(true);
      navigateToFcc({
        notice: fcapSuccessCreationMessageInFcc(business_name)
      });
    }
  }

  function onSubmitFailed(): void {
    showNotification('error', 'Failed to submit owner information!');
  }

  function onUploadFailCallback(): void {
    showNotification('error', 'Failed to upload IDs!');
  }

  const { submitContactInfo } = useSubmitContactInfo({
    onSuccessCallback: onSubmitSuccess,
    onFailCallback: onSubmitFailed
  });

  const { uploadIdentifications } = useUploadIdentifications({
    onSuccessCallback: submitContactInfo,
    onFailCallback: onUploadFailCallback
  });

  const submit = () => {
    uploadIdentifications(values);
  };

  // This is to avoid referencing handleSubmit
  // from useForm in useEffect call below
  // We don't want to monitor reference
  // changes in methods or functions
  const handleFormSubmit = () => {
    if (submitCurrentForm) handleSubmit(false);
  };

  useEffect(handleFormSubmit, [submitCurrentForm]);

  const {
    handleChange,
    handleSpecificChange,
    handleSubmit,
    values,
    errors
  } = useForm({
    defaultState: contactInformationValue,
    submitAction: submit,
    validationSchema: buildValidationSchema()
  });

  const isOwner =
    !isEmpty(values.business_relationship) &&
    values.business_relationship === 'owner';

  const handleBusinessRelationshipChange = (value: Value) => {
    const fields = [{ field: 'business_relationship', value: value }];

    if (value !== 'owner') {
      fields.push({ field: 'identifications', value: [] });
    }

    if (value !== 'other') {
      fields.push({ field: 'business_role', value: '' });
    }

    handleSpecificChange(fields);
  };

  function onChangeFileUpload(value: File | File[] | undefined | null) {
    handleSpecificChange({
      field: 'identifications',
      value: value
    });
  }

  const notFilledOut = () => {
    // custom notFilledOut method from useForm
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { work_number, identifications, business_role, ...fields } = values;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    if (isOwner) {
      fields['identifications'] = values.identifications;
    }

    if (values.business_relationship === 'other') {
      fields['business_role'] = values.business_role;
    }

    return (
      Object.entries(fields).filter(([, v]) => {
        return (
          isNil(v) ||
          v === '' ||
          v.length === 0 ||
          (typeof v === 'string' && v.trim().length === 0)
        );
      }).length !== 0
    );
  };

  // This is to avoid referencing setter
  // functions in useEffect call below
  // We don't want to monitor reference
  // changes in methods or functions
  const enableContinue = () => {
    setContinueEnabled(!notFilledOut());
  };

  useEffect(enableContinue, [values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFetchIdentificationsSuccess = (response: any) => {
    const uploadedIdentifications = response?.identifications.map(
      ({ blob, path }: { blob: ActiveStorageBlob; path: string }) => {
        const file = new File([blob], blob.filename, {
          type: blob.content_type
        });

        return { file, path };
      }
    );

    setUploadedIdentifications(uploadedIdentifications);
    handleSpecificChange({
      field: 'identifications',
      value: uploadedIdentifications
    });
  };

  const { fetchUploadedFiles } = useFetchUploadedFiles({
    onSuccessCallback: onFetchIdentificationsSuccess
  });
  const fetchFiles = () => fetchUploadedFiles('identifications');
  const onLoadActions = () => {
    fetchFiles();
    handleSpecificChange([
      { field: 'email_address', value: page_1_email_address },
      { field: 'mobile_number', value: page_1_mobile_number }
    ]);
  };

  useEffect(onLoadActions, []);

  const renderForm = () => {
    return (
      <div className="_marginTop--large" data-testid="contact-info-form">
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <DynamicSelect
              options={relationshipOptions()}
              label={LABELS[userType]['step_3'].business_relationship}
              defaultValue={values.business_relationship}
              dataTestId="business-relationship-input"
              name="business_relationship"
              placeholder="Select relationship"
              inputId="business_type"
              dataTrackSelect="business_type"
              onChange={handleBusinessRelationshipChange}
            />
          </div>
          {!isEmpty(values.business_relationship) &&
            values.business_relationship === 'other' && (
              <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--large">
                <TextInput
                  name="business_role"
                  label={LABELS[userType]['step_3'].business_role}
                  placeholder="Enter role"
                  onChange={handleChange}
                  value={values.business_role || ''}
                  isClearable={false}
                  error={errors.business_role || undefined}
                  showError
                  data-testid="business-role-input"
                  dataTrackFocus="business_role"
                />
              </div>
            )}
          {isOwner && (
            <>
              <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--normal _marginBottom--xsmall">
                <div style={{ fontSize: '0.95em', letterSpacing: '-0.028em' }}>
                  {`${LABELS[userType]['step_3'].id_upload_label}`}
                </div>
                <div
                  onClick={() => {
                    setType(ModalComponent.UPLOAD_ID);
                  }}
                  className={`${css(Styles.businessDocumentsGuide)}`}
                >
                  {`${LABELS[userType]['step_3'].id_upload_guide}`}
                </div>
              </div>

              <FileUpload
                onChange={onChangeFileUpload}
                stagedFiles={uploadedIdentifications}
              />
            </>
          )}
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="first_name"
              label={LABELS[userType]['step_3'].first_name}
              placeholder="Enter first name"
              onChange={handleChange}
              value={values.first_name}
              isClearable={false}
              error={errors.first_name}
              showError
              data-testid="first-name-input"
              dataTrackFocus="first_name"
            />
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="middle_name"
              label={LABELS[userType]['step_3'].middle_name}
              placeholder="Enter middle name"
              onChange={handleChange}
              value={values.middle_name}
              isClearable={false}
              error={errors.middle_name}
              showError
              data-testid="middle-name-input"
              dataTrackFocus="middle_name"
            />
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="last_name"
              label={LABELS[userType]['step_3'].last_name}
              placeholder="Enter last name"
              onChange={handleChange}
              value={values.last_name}
              error={errors.last_name}
              showError
              isClearable={false}
              data-testid="last-name-input"
              dataTrackFocus="last-name"
            />
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--normal _marginBottom--large">
            <div className={css(Styles.rowInputs)}>
              <div className={css(Styles.rowInput)}>
                <DynamicSelect
                  name="birth_month"
                  defaultValue={values.birth_month}
                  options={monthsOptions()}
                  onChange={(value: Value) => {
                    handleSpecificChange({
                      field: 'birth_month',
                      value: value
                    });
                  }}
                  dataTrackSelect="birthdate_month"
                  inputId="birth_month"
                  dataTestId="birth-month-select"
                  placeholder="Month"
                  label={LABELS[userType]['step_3'].birth_date}
                />
              </div>
              <div
                className={`_horizontalMargin--small-tabletUp ${css(
                  Styles.rowInput
                )}`}
              >
                <div style={{ marginTop: '24px' }}></div>
                <DynamicSelect
                  name="birth_day"
                  defaultValue={values.birth_day}
                  options={daysOptions()}
                  onChange={(value: Value) => {
                    handleSpecificChange({ field: 'birth_day', value: value });
                  }}
                  dataTrackSelect="birthdate_day"
                  inputId="birth-day-select"
                  dataTestId="birth-day-select"
                  placeholder="Day"
                  label=""
                />
              </div>
              <div className={css(Styles.rowInput)}>
                <div style={{ marginTop: '24px' }}></div>
                <DynamicSelect
                  name="birth_year"
                  defaultValue={values.birth_year}
                  options={yearsOptions()}
                  onChange={(value: Value) => {
                    handleSpecificChange({ field: 'birth_year', value: value });
                  }}
                  dataTrackSelect="birthdate_year"
                  inputId="birth-year-select"
                  dataTestId="birth-year-select"
                  placeholder="Year"
                  label=""
                />
              </div>
            </div>
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="mobile_number"
              label={LABELS[userType]['step_3'].mobile_number}
              guide={LABELS[userType]['step_3'].mobile_number_guide}
              onChange={handleChange}
              value={values.mobile_number}
              isClearable={false}
              error={errors.mobile_number}
              showError
              data-testid="mobile-number-input"
              dataTrackFocus="mobile_number"
              maxLength={11}
              placeholder="09XXXXXXXXX"
            />
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="work_number"
              label={LABELS[userType]['step_3'].work_number}
              onChange={handleChange}
              value={values.work_number}
              isClearable={false}
              error={errors.work_number}
              showError
              data-testid="work-number-input"
              dataTrackFocus="work_number"
            />
          </div>
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="email_address"
              label={LABELS[userType]['step_3'].email}
              placeholder="Enter email address"
              onChange={handleChange}
              value={values.email_address}
              error={errors.email_address}
              showError
              isClearable={false}
              data-testid="email-address-input"
              dataTrackFocus="email_address"
            />
          </div>
        </div>
      </div>
    );
  };

  return <>{isContinueLoading ? <BezierLoader /> : renderForm()}</>;
};

export default ContactInfoForm;
