import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// eslint-disable-next-line no-undef
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
interface Props {
  children: React.ReactElement;
}

const RecaptchaProvider: React.FC<Props> = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
