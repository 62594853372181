import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  otpInput: {
    width: '40px',
    height: '48px',
    margin: '0px 4px',
    fontSize: '36px',
    fontWeight: 400,
    borderRadius: '6px',
    textTransform: 'uppercase',
    border: '1px solid',
    '@media (max-width: 768px)': {
      fontSize: '18px'
    }
  },
  normalInputBox: {
    backgroundColor: '#f9fafb',
    borderColor: 'rgba(0, 0, 0, 0.3)'
  },
  errorInputBox: {
    backgroundColor: '#FEF2F2',
    borderColor: '#DC2626'
  },
  resendOtp: {
    textDecoration: 'none',
    color: '#565EF0',
    fontWeight: 600
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  errorContainer: {
    marginTop: '8px',
    color: 'rgb(220 38 38)',
    textAlign: 'center'
  },
  clearIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px'
  },
  otpGuide: {
    '@media (max-width: 768px)': { letterSpacing: '-0.028em' }
  }
});

export default styles;
