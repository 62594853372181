/* eslint-disable no-undef */
import { DirectUpload } from '@rails/activestorage';

export interface GlobalBlob extends Blob {
  signed_id: string;
}

const baseApiUrl =
  process.env.REACT_APP_API_BASE_ENDPOINT || 'http://localhost:3002';
const directUploadUrl = `${baseApiUrl}/rails/active_storage/direct_uploads`;

const directUpload = (file: File): Promise<GlobalBlob> => {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, directUploadUrl);

    upload.create((error: Error | null, blob: GlobalBlob | undefined) => {
      if (blob) {
        resolve(blob);
      } else if (error) {
        reject(error);
      }
    });
  });
};

export default directUpload;
