export interface SelectOption {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export enum PRESENTATION_TYPE {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info'
}
export interface ZeWindow extends Window {
  zESettings: {
    webWidget: {
      position: {
        horizontal: string;
        vertical: string;
      };
      offset: {
        vertical: string;
        mobile: {
          horizontal: string;
          vertical: string;
        };
      };
    };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  zE: (identifier: string, action: string, callback?: any) => boolean;
}
