import React, { useEffect, useState } from 'react';
import isNil from 'lodash-es/isNil';
import isUndefined from 'lodash-es/isUndefined';
import { css } from 'aphrodite';

import GlobalStyles from '../styles/GlobalStyles';
import { trackEvent } from 'utils/segmentTracker';

interface OwnProps {
  error?: string;
  isClearable?: boolean;
  showError?: boolean;
  label?: string;
  guide?: string;
  onClearAction?: () => void;
  dataTrackFocus?: string;
}

export type Props = OwnProps & React.HTMLProps<HTMLInputElement>;
type InputValue = string | number | readonly string[] | undefined;

const TextInput: React.FC<Props> = props => {
  const {
    error,
    showError,
    onChange,
    isClearable,
    onClearAction,
    label,
    guide,
    value,
    dataTrackFocus,
    ...attr
  } = props;

  const [inputValue, setInputValue] = useState<InputValue>(
    isNil(value) ? '' : value
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clearValue = (event: any): void => {
    if (!isUndefined(event)) {
      event.preventDefault();
    }
    if (!isUndefined(onChange)) {
      onChange(event);
    }
    if (!isNil(onClearAction)) {
      onClearAction();
    }
    setInputValue('');
  };

  const renderClearIcon = (): React.ReactElement | null => {
    if (isNil(inputValue) || inputValue === '' || !isClearable) return null;

    return (
      <span
        data-testid="clear-icon"
        onClick={clearValue}
        className={`_marginRight--small -secondary ${css(
          GlobalStyles.clearInput
        )}`}
      >
        &times;
      </span>
    );
  };

  const errorClass = !isNil(error) ? '_error' : '';

  const handleChangesInValueProp = (): void => {
    setInputValue(value);
  };

  const handleOnFocus = (): void => {
    if (dataTrackFocus) {
      trackEvent(`${dataTrackFocus}_focused`);
    }
  };

  useEffect(handleChangesInValueProp, [value]);

  return (
    <>
      <label
        className={`ds-input position-relative _marginBottom--xxxsmall ${errorClass}`}
      >
        {!isNil(label) && <span>{label}</span>}
        {!isNil(guide) && (
          <h6 className="byline _fontWeight_normal">{guide}</h6>
        )}
        <input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChange(event);
          }}
          onFocus={handleOnFocus}
          type="text"
          value={inputValue}
          {...attr}
        />
        {renderClearIcon()}
      </label>
      {!isNil(error) && showError && (
        <p
          className="-danger _marginBottom--normal"
          style={{ whiteSpace: 'pre-line' }}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default TextInput;
