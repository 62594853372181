export enum Region {
  ph_ncr = 'National Capital Region (NCR)',
  ph_car = 'Cordillera Administrative Region (CAR)',
  ph_region1 = 'Region 1 (Ilocos Region)',
  ph_region2 = 'Region 2 (Cagayan Valley)',
  ph_region3 = 'Region 3 (Central Luzon)',
  ph_region4a = 'Region 4A (CALABARZON)',
  ph_region4b = 'Region 4B (MIMAROPA)',
  ph_region5 = 'Region 5 (Bicol Region)',
  ph_region6 = 'Region 6 (Western Visayas)',
  ph_region7 = 'Region 7 (Central Visayas)',
  ph_region8 = 'Region 8 (Eastern Visayas)',
  ph_region9 = 'Region 9 (Zamboanga Peninsula)',
  ph_region10 = 'Region 10 (Northern Mindanao)',
  ph_region11 = 'Region 11 (Davao Region)',
  ph_region12 = 'Region 12 (SOCCSKSARGEN)',
  ph_region13 = 'Region 13 (Caraga Region)',
  ph_armm = 'ARMM (Autonomous Region in Muslim Mindanao)'
}

export const ServiceableRegions = [
  'ph_ncr',
  'ph_region1',
  'ph_region3',
  'ph_region4a',
  'ph_region5',
  'ph_region6',
  'ph_region7',
  'ph_region8',
  'ph_region10',
  'ph_region11',
  'ph_region13'
];

export const ExpandedServiceableRegions: Array<string> = [];
