import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#000000B3',
    position: 'fixed',
    overflowY: 'auto',
    top: 0,
    zIndex: 101
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '584px',
    backgroundColor: 'white',
    padding: '40px 32px',
    borderRadius: '4px',
    position: 'absolute',
    transform: 'translateY(-35%)',
    '@media (max-width: 768px)': {
      position: 'static'
    }
  },
  closeButton: {
    alignSelf: 'flex-end',
    color: '#565EF0',
    fontWeight: 'bold'
  },
  disc: {
    listStyle: 'disc inside',
    paddingLeft: '8px',
    lineHeight: '18px'
  },
  body: {
    fontWeight: 400,
    color: '#414141',
    fontSize: '14px'
  }
});

export default styles;
