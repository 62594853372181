import React, { useContext } from 'react';
import isNil from 'lodash-es/isNil';

import ModalContext from 'contexts/ModalContext';
import MODAL_COMPONENTS from './ModalComponents';

const Modal: React.FC = () => {
  const { type, modalProps, setType } = useContext(ModalContext);

  const hideModal = (): void => {
    setType(undefined);
  };

  if (!isNil(type)) {
    const ModalComponent = MODAL_COMPONENTS[type];
    return <ModalComponent {...modalProps} hideModal={hideModal} />;
  } else {
    return null;
  }
};

export default Modal;
