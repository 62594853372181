/* eslint-disable no-undef */
import React from 'react';

import OnboardingTemplate from 'components/templates/pageTemplate/OnboardingTemplate';
import Confirmation from 'components/features/onboarding/Confirmation';

const ConfirmationPage: React.FC = () => {
  return (
    <OnboardingTemplate
      dataTestId="confirmation-page"
      hideLegal
      showProgress={false}
    >
      <Confirmation />
    </OnboardingTemplate>
  );
};

export default ConfirmationPage;
