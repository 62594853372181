/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { isNil } from 'lodash-es';

import { buildValidationSchema } from 'validations/businessRegistration';
import { showNotification } from 'utils/toast';
import { navigateTo } from 'utils/navigation';
import { BusinessType } from 'enums/business_type';
import { Region, ServiceableRegions } from 'enums/region';
import { emptyOption } from 'interfaces/onboarding';
import { LABELS } from 'utils/labels';
import { USER_TYPE } from 'interfaces/user';

import ApplicationContext from 'contexts/ApplicationContext';
import TextInput from 'components/common/inputs/TextInput';
import useForm from 'hooks/useForm';
import useSubmitBusinessRegistration from 'hooks/onboarding/useSubmitBusinessRegistration';
import enumOptions from 'utils/enum_options';
import DynamicSelect, { Value } from 'components/common/selects/DynamicSelect';
import RaisedButton from 'components/common/buttons/RaisedButton';
import BezierLoader from 'components/common/loaders/BezierLoader';

const region_options = [emptyOption, ...enumOptions(Region)];
const type_options = [emptyOption, ...enumOptions(BusinessType)];

const BusinessRegistrationForm: React.FC = () => {
  const {
    userType,
    isContinueLoading,
    businessRegistrationInformationValue
  } = useContext(ApplicationContext);

  function onSubmitSuccess(): void {
    if (values.business_type === 'cooperative') {
      navigateTo('/pre-qualification-form/unqualified');
      return;
    }

    // NOTE: For now this workaround will do for style fixes
    const launchers = document.querySelectorAll('[id=launcher]');
    launchers.forEach((launcher: HTMLElement) => {
      launcher.style.display = 'none';
    });
    navigateTo('/pre-qualification-form/step-2');
  }

  function onSubmitFailed(): void {
    showNotification(
      'error',
      'Failed to submit business registration information!'
    );
  }

  function submit(): void {
    const params = {
      name: values.business_name,
      reg_num: values.business_reg_num,
      business_type: values.business_type,
      primary_region: values.region_of_operation,
      region: values.region_of_registration,
      email_address: values.email_address,
      mobile_number: values.mobile_number
    };
    submitBusinessRegistration(params);
  }

  const {
    submitBusinessRegistration,
    isLoading
  } = useSubmitBusinessRegistration({
    onSuccessCallback: onSubmitSuccess,
    onFailCallback: onSubmitFailed
  });

  const {
    handleChange,
    handleSubmit,
    handleSpecificChange,
    values,
    errors
  } = useForm({
    defaultState: businessRegistrationInformationValue,
    submitAction: submit,
    validationSchema: buildValidationSchema()
  });

  const unServicableRegion = !['']
    .concat(ServiceableRegions)
    .includes(values.region_of_registration);

  const notFilledOut = () => {
    // custom notFilledOut method from useForm

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { business_reg_num, ...rest } = values;
    let fields = rest;

    if (!unServicableRegion) {
      const { region_of_operation, ...rest } = fields;
      fields = rest;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    return (
      Object.entries(fields).filter(([, v]) => {
        return isNil(v) || v === '' || v.length === 0;
      }).length !== 0
    );
  };

  const handleRegistrationRegionChange = (value: Value) => {
    const fields = [{ field: 'region_of_registration', value: value }];

    if (value && ServiceableRegions.includes(value as string)) {
      fields.push({ field: 'region_of_operation', value: '' });
    }

    handleSpecificChange(fields);
  };

  const handleFormSubmit = () => {
    handleSubmit(false);
  };

  const renderForm = () => {
    return (
      <div
        className="_marginTop--large"
        data-testid="business-registration-form"
      >
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="business_name"
              label={LABELS[userType]['step_1'].business_name}
              guide={LABELS[userType]['step_1'].business_name_guide}
              placeholder={LABELS[userType]['step_1'].business_name_placeholder}
              onChange={handleChange}
              value={values.business_name}
              isClearable={false}
              data-testid="business-name-input"
              dataTrackFocus="business_name"
            />
          </div>
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="business_reg_num"
              label={`${LABELS[userType]['step_1'].business_reg_num} (Optional)`}
              placeholder={
                LABELS[userType]['step_1'].business_reg_num_placeholder
              }
              onChange={handleChange}
              value={values.business_reg_num}
              isClearable={false}
              data-testid="business-reg-num-input"
              dataTrackFocus="business_reg_num"
            />
          </div>
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <DynamicSelect
              options={type_options}
              label={LABELS[userType]['step_1'].business_type}
              defaultValue={values.business_type}
              dataTestId="business-type-input"
              name="business_type"
              placeholder="Select business type"
              inputId="business_type"
              dataTrackSelect="business_type"
              onChange={(value: Value) => {
                handleSpecificChange({
                  field: 'business_type',
                  value: value
                });
              }}
            />
          </div>
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <DynamicSelect
              options={region_options}
              defaultValue={values.region_of_registration}
              dataTestId="region-of-registration-input"
              name="region_of_registration"
              label={LABELS[userType]['step_1'].registration_region}
              placeholder="Select a region"
              inputId="region_of_registration"
              onChange={handleRegistrationRegionChange}
              dataTrackSelect="registered_region"
            />
          </div>
        </div>
        {unServicableRegion && (
          <div className="_horizontalMargin--xxxlarge-tabletUp">
            <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
              <DynamicSelect
                options={region_options}
                dataTestId="region-of-operation-input"
                defaultValue={values.region_of_operation}
                name="region_of_operation"
                label={LABELS[userType]['step_1'].operation_region}
                placeholder="Select a region"
                inputId="region_of_operation"
                onChange={(value: Value) => {
                  handleSpecificChange({
                    field: 'region_of_operation',
                    value: value
                  });
                }}
                dataTrackSelect="operating_region"
              />
            </div>
          </div>
        )}
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="email_address"
              label={LABELS[userType]['step_1'].email}
              placeholder="Enter email address"
              onChange={handleChange}
              value={values.email_address}
              error={errors.email_address}
              showError
              isClearable={false}
              data-testid="email-address-input"
              dataTrackFocus="email_address"
            />
          </div>
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp">
          <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
            <TextInput
              name="mobile_number"
              label={LABELS[userType]['step_1'].mobile_number}
              onChange={handleChange}
              value={values.mobile_number}
              isClearable={false}
              error={errors.mobile_number}
              showError
              data-testid="mobile-number-input"
              dataTrackFocus="mobile_number"
              maxLength={11}
              placeholder="09XXXXXXXXX"
            />
          </div>
        </div>
        {userType !== USER_TYPE.CAPITAL_ADVISOR && (
          <small className="byline _alignCenter _fontSize--small">
            By filling up this form, you are agreeing to First Circle's
            <a
              href="https://www.firstcircle.ph/terms-and-conditions"
              className="_horizontalMargin--xxsmall"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="terms-of-service-link"
              data-track="terms_of_service"
            >
              Terms of Service
            </a>
            and
            <a
              href="https://www.firstcircle.ph/privacy-policy"
              className="_horizontalMargin--xxsmall"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="privacy-policy-link"
              data-track="privacy_policy"
            >
              Privacy Policy
            </a>
          </small>
        )}
        <div className="_marginTop--normal">
          <RaisedButton
            type="primary"
            action={handleFormSubmit}
            fullWidth
            disabled={notFilledOut() || isLoading}
            dataTestId="registration-form-submit"
            isLoading={isLoading}
            dataTrack="submit_step1"
          >
            Submit
          </RaisedButton>
        </div>
      </div>
    );
  };

  return <>{isContinueLoading ? <BezierLoader /> : renderForm()}</>;
};

export default BusinessRegistrationForm;
