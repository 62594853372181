/* eslint-disable no-undef */
import React, { useContext } from 'react';

import { USER_TYPE } from 'interfaces/user';
import { LABELS } from 'utils/labels';

import OnboardingTemplate from 'components/templates/pageTemplate/OnboardingTemplate';
import CapitalAdvisorTemplate from 'components/templates/pageTemplate/CapitalAdvisorTemplate';

import ApplicationContext from 'contexts/ApplicationContext';
import BusinessActivityForm from 'components/features/onboarding/BusinessActivityForm';

const BusinessActivityPage: React.FC = () => {
  const { userType } = useContext(ApplicationContext);

  const props = {
    dataTestId: 'business-activity-page',
    showFooter: true,
    hideLegal: true,
    stepName: LABELS[userType]['step_2'].stepName,
    headerTitle: LABELS[userType]['step_2'].headerTitle
  };

  const Template =
    userType === USER_TYPE.CAPITAL_ADVISOR
      ? CapitalAdvisorTemplate
      : OnboardingTemplate;

  return (
    <Template {...props}>
      <BusinessActivityForm />
    </Template>
  );
};

export default BusinessActivityPage;
