import { CUSTOM_VALUES } from 'enums/analytics';
import { ZeWindow } from 'interfaces/global';
import { ZendeskEvent } from 'interfaces/onboarding';
import { trackEvent } from 'utils/segmentTracker';

declare let window: ZeWindow;

const addListener = () => {
  if (window.zE) {
    window.zE('webWidget:on', 'userEvent', function(event: ZendeskEvent) {
      if (
        Object.values(CUSTOM_VALUES).includes(event.action as CUSTOM_VALUES)
      ) {
        const index = Object.values(CUSTOM_VALUES).indexOf(
          event.action as CUSTOM_VALUES
        );
        const track_value = Object.keys(CUSTOM_VALUES)[index];
        trackEvent(track_value);
      }
    });
  }
};

const useZendeskWidget = () => {
  const script: HTMLScriptElement = document.createElement('script');
  // eslint-disable-next-line no-undef
  script.src = process.env.REACT_APP_ZENDESK_URL || '';
  script.async = true;
  script.id = 'ze-snippet';
  script.onload = addListener;
  document.body.appendChild(script);

  const settings = {
    webWidget: {
      position: {
        horizontal: 'right',
        vertical: 'bottom'
      },
      offset: {
        horizontal: '0px',
        vertical: '0px',
        mobile: {
          horizontal: 'calc(50% - 48px)',
          vertical: '5px'
        }
      }
    }
  };
  window.zESettings = settings;

  return () => {
    document.body.removeChild(script);
  };
};
export default useZendeskWidget;
