import React, { useState } from 'react';
import { PRESENTATION_TYPE } from 'interfaces/global';

interface Props {
  type: PRESENTATION_TYPE;
  header: string | undefined;
  title: string | undefined;
  message: string | undefined;
}

const Banner: React.FC<Props> = ({ type, header, title, message }) => {
  let bannerClass: string;

  const [visible, setVisible] = useState(true);

  switch (type) {
    case PRESENTATION_TYPE.SUCCESS:
      bannerClass = '-success';
      break;
    case PRESENTATION_TYPE.DANGER:
      bannerClass = '-danger';
      break;
    case PRESENTATION_TYPE.INFO:
      bannerClass = '-primary';
      break;
    case PRESENTATION_TYPE.WARNING:
      bannerClass = '-warning';
      break;
    default:
      bannerClass = '-primary';
  }

  if (!visible) {
    return null;
  }

  return (
    <div className={`ds-banner ${bannerClass}`} data-testid="banner-container">
      <small className="preamble _textAlign_center"> {header} </small>

      <p className="copy  emphasize -base">
        {title}
        <br />
        {message}
      </p>

      {/* eslint-disable-next-line */}
      <a className="dismiss" role="button" onClick={() => setVisible(false)}>
        <i className="materialIcon --micro -base"> close </i>
      </a>
    </div>
  );
};

export default Banner;
