import { forIn } from 'lodash';

interface Option {
  value: string;
  label: string;
}

export default function enumOptions(arg: { [key: string]: string }): Option[] {
  const options: Option[] = [];
  forIn(arg, (value: string, key: string) => {
    options.push({ value: key, label: value });
  });
  return options;
}
