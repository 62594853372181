import * as yup from 'yup';

export interface CommonAttributeProps {
  attribute: string;
}

export const requiredStringField = ({ attribute }: CommonAttributeProps) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .label(attribute);
