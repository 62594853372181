import React from 'react';
import { css } from 'aphrodite';
import isNil from 'lodash-es/isNil';

import Styles from './SuspenseLoaderStyles';

interface Props {
  dataTestId?: string;
}

const CapitalAdvisorLoader: React.FC<Props> = ({ dataTestId }) => {
  return (
    <div
      className={css(Styles.container)}
      data-testid={isNil(dataTestId) ? 'suspense-loader' : dataTestId}
    >
      <div className={css(Styles.content)}>
        <div>
          <div className="reverse-spinner"></div>
        </div>
      </div>
      <div className={css(Styles.capitalAdvisorLoaderText)} />
    </div>
  );
};

export default CapitalAdvisorLoader;
