/* eslint-disable no-undef */

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { datadogRum } from '@datadog/browser-rum';
import { isUndefined } from 'lodash';

import Root from 'components/routes/Root';
import './index.css';
import App from 'components/App';
import SuspenseLoader from 'components/common/loaders/SuspenseLoader';
import * as serviceWorker from './serviceWorker';
import DocumentTitle from 'components/common/DocumentTitle';
import ApplicationProvider from 'providers/ApplicationProvider';

if (!isUndefined(process.env.REACT_APP_DD_CLIENT_TOKEN)) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID as string,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    service: process.env.REACT_APP_DD_SERVICE,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENV,
    sampleRate: process.env.REACT_APP_ENV === 'production' ? 100 : 0,
    sessionReplaySampleRate:
      process.env.REACT_APP_ENV === 'production' ? 100 : 0,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    allowedTracingOrigins: [/https:\/\/.*\.firstcircle\.(ph|com|io)/]
  });
}

ReactDOM.render(
  <ApplicationProvider>
    <App>
      <React.Suspense fallback={<SuspenseLoader />}>
        <DocumentTitle />
        <Root />
      </React.Suspense>
    </App>
  </ApplicationProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
