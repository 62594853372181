import { StyleSheet } from 'aphrodite';

import { fcblue } from '../styles/GlobalStyles';

const styles = StyleSheet.create({
  container: {
    height: '100vh',
    width: '100wh',
    backgroundColor: fcblue,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },

  content: {
    width: '85px',
    height: '85px'
  },

  loaderText: {
    color: '#ffffff',
    fontSize: '24px',
    fontWeight: 'bold',
    ':before': {
      content: "'FIRST CIRCLE ONBOARDING'"
    }
  },

  capitalAdvisorLoaderText: {
    color: '#ffffff',
    fontSize: '24px',
    fontWeight: 'bold',
    ':before': {
      content: "'VALIDATING CAPITAL ADVISOR ACCESS'"
    }
  }
});

export default styles;
