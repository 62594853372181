import { toast } from 'react-toastify';

type NotificationType = 'success' | 'warning' | 'error';

export const showNotification = (
  type: NotificationType,
  message: string
): void => {
  switch (type) {
    case 'success':
      toast.success(message);
      return;
    case 'warning':
      toast.warn(message);
      return;
    case 'error':
      toast.error(message);
      return;
    default:
      return;
  }
};
