/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { requiredStringField } from './common/fields';

export const clientsField = ({ attribute }: { attribute: string }) =>
  yup
    .array()
    .when('industry', (industry: string, schema: yup.StringSchema) => {
      if (industry === 'Construction') {
        return schema;
      } else {
        return schema.required().min(1);
      }
    })
    .label(attribute);

export const requiredWhenNotConstructionField = ({
  attribute
}: {
  attribute: string;
}) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .when('industry', (industry: string, schema: yup.StringSchema) => {
      if (industry === 'Construction') {
        return schema;
      } else {
        return schema.required();
      }
    })
    .label(attribute);

const percentageValidation = yup
  .number()
  .required('This field is required')
  .min(0, 'Please provide a value between 0 and 100')
  .max(100, 'Please provide a value between 0 and 100');

export const buildValidationSchema = () => {
  const shape = {
    industry: requiredStringField({ attribute: 'Industry' }),
    clients: clientsField({ attribute: 'Clients' }),
    monthly_revenue: requiredWhenNotConstructionField({
      attribute: 'Monthly revenue'
    }),
    checks_revenue_percentage: percentageValidation,
    cash_revenue_percentage: percentageValidation,
    local_bank_transfers_revenue_percentage: percentageValidation,
    international_bank_transfers_revenue_percentage: percentageValidation,
    others_revenue_percentage: percentageValidation,
    checks_cost_percentage: percentageValidation,
    cash_cost_percentage: percentageValidation,
    local_bank_transfers_cost_percentage: percentageValidation,
    international_bank_transfers_cost_percentage: percentageValidation,
    others_cost_percentage: percentageValidation,
    total_revenue_percentage: yup
      .number()
      .integer()
      .oneOf([100], 'Total Revenue should be 100'),
    total_cost_percentage: yup
      .number()
      .integer()
      .oneOf([100], 'Total Cost should be 100')
  };

  return yup.object().shape(omitBy(shape, isNil));
};
