import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const Styles = StyleSheet.create({
  bezierLoaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '110px 0 120px'
  },
  text: {
    fontSize: '24px',
    marginTop: '56px',
    color: '#5760f0'
  }
});

const BezierLoader: React.FC = () => {
  return (
    <div
      data-testid="bezier-loader"
      className={css(Styles.bezierLoaderContainer)}
    >
      <div className="bezier-spinner-container">
        <div className="bezier-spinner-forward"></div>
        <div className="bezier-spinner-backward"></div>
      </div>

      <div className={css(Styles.text)}>Processing...</div>
    </div>
  );
};

export default BezierLoader;
