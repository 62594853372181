import { useEffect, useRef, useState } from 'react';

const useTimer = (seconds: number) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [running, setRunning] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(seconds);

  useEffect(() => {
    const tick = () => {
      setCountDown(prevCountDown => prevCountDown - 1);
    };

    if (running) {
      intervalRef.current = setInterval(tick, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [running]);

  useEffect(() => {
    if (!running) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [running]);

  const startTimer = () => {
    setRunning(true);
  };

  const stopTimer = () => {
    setRunning(false);
    setCountDown(seconds);
  };

  return {
    running,
    countDown,
    startTimer,
    stopTimer
  };
};

export default useTimer;
