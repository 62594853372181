import React from 'react';
import isNil from 'lodash-es/isNil';
import { css } from 'aphrodite';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import { trackEvent } from 'utils/segmentTracker';

interface OwnProps {
  error?: string;
  showError?: boolean;
  multi?: boolean;
  id?: string;
  label: string;
  dataTrackCheck?: string;
}

type Props = OwnProps & React.HTMLProps<HTMLInputElement>;

const Checkbox: React.FC<Props> = props => {
  const {
    label,
    error,
    onChange,
    name,
    checked,
    showError,
    id,
    multi,
    dataTrackCheck,
    ...attr
  } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) onChange(event);
    if (dataTrackCheck) trackEvent(`${dataTrackCheck}_clicked`);
  };

  return (
    <div>
      <div className="ds-checkbox">
        <input
          {...attr}
          className="checkbox"
          type="checkbox"
          onChange={handleOnChange}
          checked={checked}
          name={name}
          data-trackcheck={dataTrackCheck}
          id={
            multi && !isNil(id)
              ? `${name}-checkbox-input-${id}`
              : `${name}-checkbox-input`
          }
        />
        <label
          id="checkbox-label"
          htmlFor={
            multi && !isNil(id)
              ? `${name}-checkbox-input-${id}`
              : `${name}-checkbox-input`
          }
          className={`ds-btn ${css(GlobalStyles.checkbox)}`}
        >
          <i className="materialIcon --micro _off">check_box_outline_blank</i>
          <i className="materialIcon --micro _on">check_box</i>
          {label}
        </label>
      </div>
      {!isNil(error) && showError && <p className="-danger">{error}</p>}
    </div>
  );
};

export default Checkbox;
