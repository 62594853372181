/* eslint-disable @typescript-eslint/no-explicit-any */

import jwtDecode from 'jwt-decode';
import { isUndefined, isNil, isEmpty } from 'lodash-es';
import { USER_TYPE } from 'interfaces/user';

export const urlParams = (): null | Record<string, any> => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (Object.keys(params).length === 0) return null;

  return params;
};

export const stringUrlParams = () => {
  return window.location.search;
};

export const urlHasParameters = () => {
  const params = urlParams();
  return !isNil(params);
};

export const decodeToken = (token: string) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return undefined;
  }
};

export const tryingAsCapitalAdvisor = (): boolean => {
  const params = urlParams();

  if (!isNil(params)) {
    if (!isUndefined(params.capital_advisor_token)) {
      if (isEmpty(params.capital_advisor_token)) return true;

      const decodedToken: any = decodeToken(params.capital_advisor_token);
      if (isUndefined(decodedToken)) return true;
      if (isUndefined(decodedToken.agent_code)) return true;
      if (isUndefined(decodedToken.user_type)) return true;
      if (isUndefined(decodedToken.exp)) return true;
    }
  }

  return false;
};

export const isACapitalAdvisorUser = (): boolean => {
  const params = urlParams();

  if (isUndefined(params) || isNil(params)) return false;
  if (isNil(params.capital_advisor_token)) return false;
  if (isEmpty(params.capital_advisor_token)) return false;

  const decodedToken: any = decodeToken(params.capital_advisor_token);

  if (tryingAsCapitalAdvisor()) return false;
  if (decodedToken.user_type !== USER_TYPE.CAPITAL_ADVISOR) return false;

  return true;
};
