import React, { useContext } from 'react';

import './App.css';
import ModalProvider from 'providers/ModalProvider';
import RecaptchaProvider from 'providers/RecaptchaProvider';
import { urlParams } from 'utils/url';

import { isACapitalAdvisorUser, tryingAsCapitalAdvisor } from 'utils/url';
import {
  setCapitalAdvisorTokenCookie,
  clearCapitalAdvisorTokenCookie
} from 'utils/cookie';
import { isNil } from 'lodash';
import { USER_TYPE } from 'interfaces/user';
import { errorType } from 'utils/error';

import useValidateCapitalAdvisorToken from 'hooks/onboarding/useValidateCapitalAdvisorToken';
import CapitalAdvisorLoader from './common/loaders/CapitalAdvisorLoader';
import ApplicationContext from 'contexts/ApplicationContext';
import CapitalAdvisorInvalid from './common/CapitalAdvisorInvalid';
import ErrorPage from './common/errors/ErrorPage';
import RedirectingToFcc from './common/RedirectingToFcc';
import SuspenseLoader from './common/loaders/SuspenseLoader';
import useDesignSystemLoader from 'hooks/useDesignSystemLoader';

interface Props {
  children: React.ReactElement;
}

const App = ({ children }: Props): React.ReactElement | null => {
  const {
    userType,
    setUserType,
    invalidAccess,
    setInvalidAccess,
    redirectingToFcc
  } = useContext(ApplicationContext);

  const {
    isLoading: isValidatingCapitalAdvisorToken,
    error: errorValidatingToken,
    validateAccessToken
  } = useValidateCapitalAdvisorToken();

  function handleOnload(): void {
    // For scenarios where user is trying to add
    // capital_advisor_token in url params and it's not valid.
    if (tryingAsCapitalAdvisor()) {
      setInvalidAccess(true);
      return;
    }

    // If user is a capital advisor based on url params and the token.
    if (isACapitalAdvisorUser()) {
      const params = urlParams();

      if (!isNil(params)) {
        setCapitalAdvisorTokenCookie(params.capital_advisor_token);

        // This will set user type to capital_advisor on success.
        validateAccessToken();
      }
    } else {
      setUserType(USER_TYPE.INBOUND);

      // This is just to make sure that we dont have the capital advisor token in cookies.
      clearCapitalAdvisorTokenCookie();
    }

    setUserType(USER_TYPE.INBOUND);
    clearCapitalAdvisorTokenCookie();
  }

  const { designSystemLoaded } = useDesignSystemLoader(handleOnload);

  let component = null;

  // Show a loading presentations while the app
  // still loads the design system.
  if (isNil(component) && !designSystemLoaded) {
    component = <SuspenseLoader />;
  }

  // This will be useful for scenarios
  // where we are redirecting the user
  // for instance FCC and user like FCAP users.
  // We dont want them clicking items in the page
  // while the redirect is still happening. Instead
  // We will show them a redirecting message.
  if (isNil(component) && redirectingToFcc) {
    component = <RedirectingToFcc />;
  }

  if (isNil(component) && errorValidatingToken && !invalidAccess) {
    component = (
      <ErrorPage type={errorType(errorValidatingToken)} styleType="large" />
    );
  }

  if (isNil(component) && isValidatingCapitalAdvisorToken) {
    component = <CapitalAdvisorLoader />;
  }

  if (isNil(component) && invalidAccess) {
    component = <CapitalAdvisorInvalid />;
  }

  if (isNil(component) && userType !== USER_TYPE.UNKNOWN) {
    component = (
      <RecaptchaProvider>
        <ModalProvider>{children}</ModalProvider>
      </RecaptchaProvider>
    );
  }

  return <div className="ds-container">{component}</div>;
};

export default App;
