import React from 'react';
import { css } from 'aphrodite';
import { StyleSheet } from 'aphrodite';
import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { errorColor, fcblue } from './styles/GlobalStyles';

const Styles = StyleSheet.create({
  container: {
    color: fcblue
  },

  content: {
    width: '85px',
    height: '85px'
  },

  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: fcblue
  }
});

const CapitalAdvisorInvalid: React.FC = () => {
  return (
    <div
      className={`ds-container ${css(Styles.container)}`}
      data-testid="capital-advisor-invalid"
    >
      <div className="grid _verCenterItems-fromTableView">
        <div className="ds-card _padding--large _alignCenter _marginTop--xlarge">
          <FontAwesomeIcon
            icon={faUserAltSlash}
            size={'3x'}
            color={errorColor}
          />
          <div className={`${css(Styles.text)} _marginTop--small`}>
            Oops! You are trying to access as a Capital Advisor.
          </div>
          <div className="_marginTop--xsmall">
            However, the access token is invalid. Please contact support if
            problem still persists.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapitalAdvisorInvalid;
