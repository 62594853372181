import { isUndefined } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setRumUser = (responseData: any) => {
  if (isUndefined(responseData) || isUndefined(responseData.attributes)) return;

  let { contactGivenName: name } = responseData.attributes;
  const { agentCode, email, firstName, lastName } = responseData.attributes;

  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  }

  // Set authenticate user for RUM
  datadogRum.setUser({
    id: responseData.id,
    name: name,
    email: email,
    agentCode: agentCode
  });
};

export default setRumUser;
