import React from 'react';

interface Props {
  hideModal: () => void;
}

const ModalTopCloseButton: React.FC<Props> = ({ hideModal }) => {
  return (
    <div data-testid="modal-close">
      <button
        className="ds-btn -secondary --micro _icon _rounded _closeModal"
        data-testid="modal-close-button"
        onClick={hideModal}
      >
        <i className="materialIcon">close</i>
      </button>
    </div>
  );
};

export default ModalTopCloseButton;
