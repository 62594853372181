export enum PAGES {
  step_1 = 'step_1_viewed',
  step_2 = 'step_2_viewed',
  step_3 = 'step_3_viewed',
  step_4 = 'step_4_viewed',
  step_5 = 'step_5_viewed',
  step_6 = 'step_6_viewed',
  unqualified = 'unqualified_viewed',
  unspecified = 'unspecified_page_viewed'
}

export enum CUSTOM_VALUES {
  // industries
  industry_accommodation_and_hospitality = 'Accommodation & Hospitality',
  industry_automobiles_and_components = 'Automobiles & Components',
  industry_banks = 'Banks',
  industry_commercial_and_professional_services = 'Commercial & Professional Services',
  industry_construction = 'Construction',
  industry_construction_and_hardware_materials = 'Construction & Hardware Materials',
  industry_consumer_durables_and_apparel = 'Consumer Durables & Apparel',
  industry_consumer_services = 'Consumer Services',
  industry_diversified_financials = 'Diversified Financials',
  industry_energy = 'Energy',
  industry_events_and_entertainment = 'Events & Entertainment',
  industry_food_and_staples_retailing = 'Food & Staples Retailing',
  industry_food_beverage_and_tobacco = 'Food, Beverage & Tobacco',
  industry_healthcare_equipment = 'Healthcare equipment',
  industry_household_and_personal_products = 'Household & Personal Products',
  industry_installation = 'Installation',
  industry_insurance = 'Insurance',
  industry_manufacturing = 'Manufacturing',
  industry_materials = 'Materials',
  industry_media = 'Media',
  industry_pharmaceuticals_biotechnology_and_life_sciences = 'Pharmaceuticals, Biotechnology & Life Sciences',
  industry_real_estate = 'Real Estate',
  industry_semiconductors_and_semiconductor_equipment = 'Semiconductors & Semiconductor Equipment',
  industry_services = 'Services',
  industry_specialized_construction = 'Specialized Construction',
  industry_software_and_services = 'Software & Services',
  industry_technology_hardware_and_equipment = 'Technology Hardware & Equipment',
  industry_telecommunication_services = 'Telecommunication Services',
  industry_trading = 'Trading',
  industry_transportation = 'Transportation',
  // monthlyRevenue
  monthly_revenue_less_than_400k = '₱400,000 or less',
  monthly_revenue_400k_to_800k = '₱400,001 to ₱800,000',
  monthly_revenue_800k_to_1_25M = '₱800,001 to ₱1,250,000',
  monthly_revenue_1_25M_to_2_5M = '₱1,250,001 to ₱2,500,000',
  monthly_revenue_2_5M_to_4M = '₱2,500,001 to ₱4,000,000',
  monthly_revenue_4M_to_16M = '₱4,000,001 to ₱16,000,000',
  monthly_revenue_more_than_16 = 'More than ₱16,000,000',
  // role
  role_owner_or_co_owner = 'Owner / Co-Owner',
  role_executive_management = 'Executive Management',
  role_finance_manager = 'Finance Manager',
  role_treasurer = 'Treasurer',
  role_secretary_or_assistant = 'Secretary / Assistant',
  role_employee = 'Employee',
  // call_time
  call_time_9AM_to_12NN = '9:00 AM to 12:00 NN',
  call_time_1PM_to_3PM = '1:00 PM to 3:30 PM',
  call_time_3PM_to_6PM = '3:30 PM to 6:00 PM',
  // zendesk
  zendesk_open = 'Web Widget Opened',
  zendesk_close = 'Web Widget Minimised'
}
