import React, { useContext } from 'react';
import { css } from 'aphrodite';

import { CommonModalProps } from 'interfaces/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ApplicationContext from 'contexts/ApplicationContext';
import { USER_TYPE } from 'interfaces/user';
import styles from './ModalStyles';

type Props = CommonModalProps;

const UploadIDModal: React.FC<Props> = ({ hideModal }) => {
  const { userType } = useContext(ApplicationContext);
  const acceptableIds = [
    'Passport',
    "Driver's License",
    'TIN ID',
    'PRC ID (front and back)',
    'Postal ID',
    'Unified Multi-Purpose ID',
    'ACR ID (for foreigners)'
  ];

  return (
    <div
      data-testid="upload-id-modal"
      className={`${css(styles.modalWrapper)}`}
    >
      <div className={`${css(styles.modalContent)}`}>
        <div onClick={hideModal} className={`${css(styles.closeButton)}`}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
          Guide to uploading {userType === USER_TYPE.INBOUND ? 'your' : 'their'}{' '}
          ID
        </div>
        <div className={`${css(styles.body)}`}>
          <div style={{ marginTop: '24px' }}>
            List of IDs accepted:
            <ul className={`${css(styles.disc)}`}>
              {acceptableIds.map((id: string) => {
                return (
                  <li key={id} className={`${css(styles.disc)}`}>
                    {id}
                  </li>
                );
              })}
            </ul>
          </div>
          <div style={{ marginTop: '12px' }}>
            General Submission Criteria:
            <ul className={`${css(styles.disc)}`}>
              <li className={`${css(styles.disc)}`}>
                Information must be clear and complete
              </li>
              <li className={`${css(styles.disc)}`}>
                ID must be valid, and not expired
              </li>
              <li className={`${css(styles.disc)}`}>
                ID must have a visible specimen signature
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadIDModal;
