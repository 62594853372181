import { isAfter, parseJSON } from 'date-fns';
import isUndefined from 'lodash-es/isUndefined';

export const environment = (): string => {
  const parsedUrl = new URL(window.location.href);
  let environment = 'production';

  if (parsedUrl.hostname.match(/staging/)) {
    environment = 'staging';
  } else if (parsedUrl.hostname.match(/preprod/)) {
    environment = 'preprod';
  } else if (parsedUrl.hostname.match(/mirror/)) {
    environment = 'mirror';
  } else if (parsedUrl.hostname.match(/localhost/)) {
    environment = 'development';
  }

  return environment;
};

export const hasUpcomingMaintenance = (): boolean => {
  // eslint-disable-next-line no-undef
  const expirationDate = process.env.REACT_APP_MAINTENANCE_EXPIRATION_DATE;

  if (isUndefined(expirationDate)) return false;

  return isAfter(parseJSON(expirationDate), new Date());
};
