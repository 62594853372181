export enum CorporationBusinessRelationships {
  owner = 'I own at least 10% of the business',
  finance_team_member = 'Finance Team Member',
  board_of_director = 'Board of Director',
  company_executive = 'Company Executive',
  other = 'Other'
}

export enum SolePropBusinessRelationships {
  owner = "I'm the registered owner",
  spouse_of_owner = "I'm the spouse of the registered owner",
  admin_or_staff = 'Admin/Staff',
  other = 'Other'
}

export enum PartnershipBusinessRelationships {
  owner = "I'm the managing partner",
  finance_team_member = 'Finance Team Member',
  company_executive = 'Company Executive',
  other = 'Other'
}

export enum CorporationBusinessRelationshipsForAdvisors {
  owner = 'Owns at least 10% of the business',
  finance_team_member = 'Finance Team Member',
  board_of_director = 'Board of Director',
  company_executive = 'Company Executive',
  other = 'Other'
}

export enum SolePropBusinessRelationshipsForAdvisors {
  owner = 'Registered owner',
  spouse_of_owner = 'Spouse of the registered owner',
  admin_or_staff = 'Admin/Staff',
  other = 'Other'
}

export enum PartnershipBusinessRelationshipsForAdvisors {
  owner = 'Managing partner',
  finance_team_member = 'Finance Team Member',
  company_executive = 'Company Executive',
  other = 'Other'
}
