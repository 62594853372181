import { navigate } from '@reach/router';
import { isNil, isEmpty } from 'lodash-es';
import querystring from 'query-string';

// NOTE: Please use this only when navigating inside the app routes.
export const navigateTo = (path: string | undefined) => {
  if (isNil(path)) return;

  const urlParams = window.location.search;

  if (!isEmpty(urlParams)) {
    navigate(`${path}${urlParams}`);
  } else {
    navigate(path);
  }
};

export const navigateToFcSite = () => {
  window.location.assign('http://www.firstcircle.ph');
};

// Used window location change here because it seems
// reach router's navigate has an issue navigating to an external
// URL while DATA DOG's RUM is activated.
// See Index.tsx for DD RUM.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const navigateToFcc = (params?: Record<string, any>) => {
  const fccBaseApiUrl =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_FCC_ABSOLUTE_URL || 'http://localhost:3000';

  if (!isNil(params)) {
    const urlParams = querystring.stringify(params);
    window.location.assign(
      `${fccBaseApiUrl}/central/leads/search?${urlParams}`
    );
  } else {
    window.location.assign(`${fccBaseApiUrl}/central/leads/search`);
  }
};

export const fcapSuccessCreationMessageInFcc = (businessName: string) => {
  return `${businessName} has been successfully submitted`;
};
