import React from 'react';

import { ApplicationContext as Context } from 'interfaces/application';
import {
  financingNeedValueDefault,
  businessActivityValueDefault,
  businessRegistrationInformationValueDefault,
  contactInformationDefault,
  endInformationValueDefault,
  utmInformationValueDefault
} from 'interfaces/onboarding';
import { USER_TYPE } from 'interfaces/user';

export const defaultContextValue: Context = {
  pageHardRefreshed: true,
  leadId: '',
  stepNumber: 1,
  isContinueEnabled: false,
  isBackEnabled: false,
  isQualified: true,
  isContinueLoading: false,
  submitCurrentForm: false,
  utmInformationValue: utmInformationValueDefault,
  contactInformationValue: contactInformationDefault,
  financingNeedValue: financingNeedValueDefault,
  businessActivityValue: businessActivityValueDefault,
  endInformationValue: endInformationValueDefault,
  businessRegistrationInformationValue: businessRegistrationInformationValueDefault,
  userType: USER_TYPE.UNKNOWN,
  capitalAdvisorUser: null,
  invalidAccess: false,
  redirectingToFcc: false,
  seenProductStory: false,
  setSubmitCurrentForm: () => null,
  setContinueIsLoading: () => null,
  setUTMInformationValue: () => null,
  setContactInformationValue: () => null,
  setFinancingNeedValue: () => null,
  setBusinessActivityValue: () => null,
  setEndInformationValue: () => null,
  setPageHardRefreshed: () => null,
  setStepNumber: () => null,
  setLeadId: () => null,
  setContinueEnabled: () => false,
  setBackEnabled: () => true,
  setQualified: () => null,
  setBusinessRegistrationInformationValue: () => null,
  resetContinueState: () => null,
  setUserType: () => null,
  setCapitalAdvisorUser: () => null,
  setInvalidAccess: () => null,
  setRedirectingToFcc: () => null,
  setSeenProductStory: () => null
};

const ApplicationContext = React.createContext<Context>(defaultContextValue);

export default ApplicationContext;
