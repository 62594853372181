/* eslint-disable no-undef */
import React, { useContext } from 'react';

import { USER_TYPE } from 'interfaces/user';

import OnboardingTemplate from 'components/templates/pageTemplate/OnboardingTemplate';
import CapitalAdvisorTemplate from 'components/templates/pageTemplate/CapitalAdvisorTemplate';
import DisqualificationPage from 'components/features/onboarding/DisqualificationPage';

import ApplicationContext from 'contexts/ApplicationContext';

const UnqualifiedPage: React.FC = () => {
  const { userType } = useContext(ApplicationContext);
  const Template =
    userType === USER_TYPE.CAPITAL_ADVISOR
      ? CapitalAdvisorTemplate
      : OnboardingTemplate;

  return (
    <Template
      dataTestId="disqualified-info-page"
      hideLegal
      showProgress={false}
    >
      <DisqualificationPage />
    </Template>
  );
};

export default UnqualifiedPage;
