/* eslint-disable no-undef */
import React, { useContext } from 'react';

import { USER_TYPE } from 'interfaces/user';
import { LABELS } from 'utils/labels';

import OnboardingTemplate from 'components/templates/pageTemplate/OnboardingTemplate';
import CapitalAdvisorTemplate from 'components/templates/pageTemplate/CapitalAdvisorTemplate';

import ApplicationContext from 'contexts/ApplicationContext';
import ContactInfoForm from 'components/features/onboarding/ContactInfoForm';

const ContactInfoPage: React.FC = () => {
  const { userType } = useContext(ApplicationContext);

  const props = {
    dataTestId: 'contact-info-page',
    showFooter: true,
    stepName: LABELS[userType]['step_3'].stepName,
    headerTitle: LABELS[userType]['step_3'].headerTitle
  };

  const Template =
    userType === USER_TYPE.CAPITAL_ADVISOR
      ? CapitalAdvisorTemplate
      : OnboardingTemplate;

  return (
    <Template {...props}>
      <ContactInfoForm />
    </Template>
  );
};

export default ContactInfoPage;
