import React from 'react';
import { css } from 'aphrodite';
import { StyleSheet } from 'aphrodite';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fcblue } from './styles/GlobalStyles';

const Styles = StyleSheet.create({
  container: {
    color: fcblue
  },

  content: {
    width: '85px',
    height: '85px'
  },

  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: fcblue
  }
});

const RedirectingToFcc: React.FC = () => {
  return (
    <div
      className={`ds-container ${css(Styles.container)}`}
      data-testid="redirecting-to-fcc"
    >
      <div className="grid _verCenterItems-fromTableView">
        <div className="ds-card _padding--large _alignCenter _marginTop--xlarge">
          <FontAwesomeIcon icon={faInfoCircle} size={'3x'} color={fcblue} />
          <div className={`${css(Styles.text)} _marginTop--small`}>
            Hey! We are redirecting you to FCC.
          </div>
          <div className="_marginTop--xsmall">Please wait a moment.</div>
        </div>
      </div>
    </div>
  );
};

export default RedirectingToFcc;
