import React, { useContext, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { isUndefined } from 'lodash-es';

import ApplicationContext from 'contexts/ApplicationContext';
import { RouteComponentProps } from '@reach/router';
import querystring from 'query-string';
import { UTMInformation } from 'interfaces/onboarding';

import BuildRoutes from './RoutesHelper';

const Root: React.FC<RouteComponentProps> = () => {
  const { setUTMInformationValue } = useContext(ApplicationContext);

  /* eslint-disable-next-line no-undef */
  if (!isUndefined(process.env.REACT_APP_DD_CLIENT_TOKEN)) {
    datadogRum.startSessionReplayRecording();
  }

  function handleUrlParams(): void {
    const parsedQuery: UTMInformation = {
      ...querystring.parse(window.location.search)
    };
    setUTMInformationValue(parsedQuery);
  }

  // Url params
  useEffect(handleUrlParams, []);

  return (
    <div className="ds-container">
      <div data-testid="root-component">
        <BuildRoutes />
      </div>
    </div>
  );
};

export default Root;
