import { useEffect } from 'react';
import useZendeskWidget from './useZendeskWidget';
import { trackOnClick, trackOnVisit } from 'utils/segmentTracker';

const useTemplateRunner = () => {
  const pathName = window.location.pathname.split('/');
  const page = pathName[pathName.length - 1].replace('-', '_');

  const zendeskLauncher = () => {
    const zeLauncherCount = document.querySelectorAll('[id=launcher]').length;

    // NOTE: Only Launch ZendeskWidget on Step 1 to avoid multiple launcher
    if (zeLauncherCount === 0 && page === 'step_1') return useZendeskWidget;

    /* eslint no-empty-function : [0, {arrowFunctions: true}] */
    return () => undefined;
  };

  useEffect(zendeskLauncher(), []);
  useEffect(trackOnClick, []);

  useEffect(() => {
    if (page === 'step_1') trackOnVisit();

    return () => {
      const currentPath = window.location.pathname.split('/');
      const currentPage = currentPath[currentPath.length - 1].replace('-', '_');
      if (currentPage !== 'step_1') trackOnVisit();
    };
  }, [page]);
};

export default useTemplateRunner;
