import SampleModal from './SampleModal';
import BusinessDocumentsModal from './BusinessDocumentsModal';
import UploadIDModal from './UploadIDModal';

// You can edit this and remove sample modal
export enum ModalComponent {
  SAMPLE_MODAL = 'SAMPLE_MODAL',
  BUSINESS_DOCUMENTS = 'BUSINESS_DOCUMENTS',
  UPLOAD_ID = 'UPLOAD_ID'
}

interface ModalComponents {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MODAL_COMPONENTS: ModalComponents = {
  [ModalComponent.SAMPLE_MODAL]: SampleModal,
  [ModalComponent.BUSINESS_DOCUMENTS]: BusinessDocumentsModal,
  [ModalComponent.UPLOAD_ID]: UploadIDModal
};

export default MODAL_COMPONENTS;
