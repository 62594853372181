import React from 'react';

import { CommonModalProps } from 'interfaces/modal';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';

export interface OwnProps {
  foo: string;
}

type Props = OwnProps & CommonModalProps;

const SampleModalModal: React.FC<Props> = ({ hideModal }) => {
  return (
    <div className="ds-modal _open" data-testid="sample-modal">
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-pr600">
            <h1 className="subhead -white">Sample Modal</h1>
          </section>
          <ModalTopCloseButton hideModal={hideModal} />
          <div>Modal Content</div>
        </div>
      </div>
    </div>
  );
};

export default SampleModalModal;
