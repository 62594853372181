import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    height: '100vh'
  },
  top: {
    height: '10%'
  },
  middle: {
    minHeight: '80%',
    border: '1px solid transparent',
    '@media (max-width: 768px)': { paddingBottom: '20%' }
  },
  bottom: {
    height: '10%',
    '@media (max-width: 768px)': {
      bottom: 0,
      position: 'fixed',
      width: '100%',
      zIndex: 1
    }
  },
  stickyBottom: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  fullHeight: {
    minHeight: '100vh'
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '0.5rem 2rem',
    '@media (max-width: 768px)': { padding: '0' }
  },
  navBarLogoContainer: {
    '@media (max-width: 768px)': { width: '50%' }
  },
  navBarLogo: {
    width: '30%',
    '@media (max-width: 768px)': { width: '75%' }
  },
  clientsCheckbox: {
    width: '70%',
    '@media (max-width: 768px)': { width: '100%' }
  },
  rowInputs: {
    display: 'flex',
    '@media (max-width: 768px)': { display: 'block' }
  },
  rowInput: {
    flex: 1,
    '@media (max-width: 768px)': { marginTop: '12px' }
  },
  roleInput: {
    padding: '0 130px 0 130px',
    '@media (max-width: 768px)': { padding: 0 }
  },
  mailLogo: {
    width: '80px'
  },
  check: {
    width: '112px'
  },
  congratulations: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#565EF0'
  },
  majesticMargin: {
    lineHeight: '24px',
    marginLeft: '140px',
    marginRight: '140px',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  stepNumberBadge: {
    borderRadius: '12px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '9px',
    paddingRight: '9px'
  },

  // Progress bar
  progressContainer: {
    margin: '20px 90px 0 90px',
    '@media (max-width: 768px)': { margin: 0 }
  },
  barWrapper: {
    padding: 0,
    height: '18px'
  },
  bar: {
    padding: 0,
    fontSize: '10px'
  },
  percentageText: {
    width: '100%',
    marginTop: '2px',
    padding: '2px'
  },

  // disqualification
  goBackMessage: {
    textAlign: 'right',
    '@media (max-width: 768px)': { textAlign: 'left' }
  },

  helpContainer: {
    display: 'flex',
    '@media (max-width: 768px)': {
      justifyContent: 'space-between',
      width: 'calc(50% + 40px)'
    }
  },
  helpButton: {
    backgroundColor: '#5760f0',
    borderRadius: '999rem',
    color: '#fff',
    fontFamily:
      'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif',
    fontSize: '1.07143rem',
    fontWeight: 800,
    letterSpacing: '0.6px',
    padding: '0.92857rem 1.57143rem',
    '@media (max-width: 768px)': { padding: '0.92857rem' }
  },
  businessDocumentsGuide: {
    color: '#565EF0',
    marginTop: '8px',
    fontWeight: 'bold',
    fontSize: '0.95em',
    letterSpacing: '-0.028em',
    width: 'fit-content',
    ':hover': {
      color: 'rgb(129 140 248)'
    }
  }
});

export default styles;
