import { useState } from 'react';
import { ApplicationContext } from 'interfaces/application';
import {
  FinancingNeed,
  financingNeedValueDefault,
  businessActivityValueDefault,
  BusinessActivity,
  BusinessRegistrationInformation,
  businessRegistrationInformationValueDefault,
  ContactInformation,
  contactInformationDefault,
  endInformationValueDefault,
  EndInformation,
  utmInformationValueDefault,
  UTMInformation
} from 'interfaces/onboarding';
import { CapitalAdvisorUser, USER_TYPE } from 'interfaces/user';

const useApplicationContextUpdater = (): ApplicationContext => {
  const [leadId, setLeadId] = useState<string | null>('');
  const [pageHardRefreshed, setPageHardRefreshed] = useState<boolean>(true);
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [isContinueEnabled, setContinueEnabled] = useState<boolean>(false);
  const [isBackEnabled, setBackEnabled] = useState<boolean>(true);
  const [isContinueLoading, setContinueIsLoading] = useState<boolean>(false);
  const [isQualified, setQualified] = useState<boolean>(true);
  const [submitCurrentForm, setSubmitCurrentForm] = useState<boolean>(false);
  const [utmInformationValue, setUTMInformationValue] = useState<
    UTMInformation
  >(utmInformationValueDefault);
  const [contactInformationValue, setContactInformationValue] = useState<
    Partial<ContactInformation>
  >(contactInformationDefault);
  const [financingNeedValue, setFinancingNeedValue] = useState<FinancingNeed>(
    financingNeedValueDefault
  );
  const [businessActivityValue, setBusinessActivityValue] = useState<
    BusinessActivity
  >(businessActivityValueDefault);
  const [
    businessRegistrationInformationValue,
    setBusinessRegistrationInformationValue
  ] = useState<BusinessRegistrationInformation>(
    businessRegistrationInformationValueDefault
  );
  const [endInformationValue, setEndInformationValue] = useState<
    EndInformation
  >(endInformationValueDefault);
  const [userType, setUserType] = useState<USER_TYPE>(USER_TYPE.UNKNOWN);
  const [
    capitalAdvisorUser,
    setCapitalAdvisorUser
  ] = useState<CapitalAdvisorUser | null>(null);
  const [invalidAccess, setInvalidAccess] = useState<boolean>(false);
  const [redirectingToFcc, setRedirectingToFcc] = useState<boolean>(false);
  const [seenProductStory, setSeenProductStory] = useState<boolean>(false);

  function resetContinueState(): void {
    setSubmitCurrentForm(false);
    setContinueIsLoading(false);
  }

  return {
    leadId,
    pageHardRefreshed,
    setPageHardRefreshed,
    stepNumber,
    isContinueEnabled,
    isBackEnabled,
    isQualified,
    setLeadId,
    setStepNumber,
    setContinueEnabled,
    setBackEnabled,
    setQualified,
    isContinueLoading,
    submitCurrentForm,
    setContinueIsLoading,
    setSubmitCurrentForm,
    financingNeedValue,
    setFinancingNeedValue,
    resetContinueState,
    businessActivityValue,
    setBusinessActivityValue,
    utmInformationValue,
    setUTMInformationValue,
    contactInformationValue,
    setContactInformationValue,
    businessRegistrationInformationValue,
    setBusinessRegistrationInformationValue,
    endInformationValue,
    setEndInformationValue,
    userType,
    setUserType,
    capitalAdvisorUser,
    setCapitalAdvisorUser,
    invalidAccess,
    setInvalidAccess,
    redirectingToFcc,
    setRedirectingToFcc,
    seenProductStory,
    setSeenProductStory
  };
};

export default useApplicationContextUpdater;
