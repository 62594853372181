/* eslint-disable no-undef */
import isUndefined from 'lodash-es/isUndefined';
import { SegmentWindow, SegmentPayload } from 'interfaces/segment';
import { CUSTOM_VALUES, PAGES } from '../enums/analytics';
import { Value } from 'components/common/selects/DynamicSelect';

declare let window: SegmentWindow;

const hasSegmentSDK = (window: SegmentWindow) =>
  !!window.analytics && !!window.segmentContext;

const disableSendingEvent = () => {
  if (
    !isUndefined(process.env.REACT_APP_DISABLE_SEGMENT) &&
    process.env.REACT_APP_DISABLE_SEGMENT === 'true'
  ) {
    return true;
  }
  if (!hasSegmentSDK(window)) return true;
};

const setContext = (context: object): void => {
  window.segmentContext = {
    ...window.segmentContext,
    ...context
  };
};

const buildDefaultPayload = (useDefaultPageName?: boolean) => {
  const timestamp = new Date().toString();
  const { pageName } = window.segmentContext;
  let payload: SegmentPayload = {
    path: window.location.pathname,
    triggeredAt: timestamp
  };
  if (useDefaultPageName) {
    payload = { ...payload, pageName };
  }

  return payload;
};

export const identify = (userId: number, payload: SegmentPayload) => {
  if (disableSendingEvent()) return false;
  if (window.location.hostname === 'localhost') {
    return console.log(`identify userId: ${userId}`);
  }

  window.analytics.identify(userId, {
    ...payload
  });
};

// For page view event
export const trackPageView = (pageName: string, payload?: SegmentPayload) => {
  if (disableSendingEvent()) return false;
  if (window.location.hostname === 'localhost') {
    return console.log(`pageView on ${pageName}`);
  }

  window.analytics.page(pageName, {
    ...buildDefaultPayload(),
    ...payload
  });
};

// For events like clicks, etc
export const trackEvent = (eventName: string, payload?: SegmentPayload) => {
  if (disableSendingEvent()) return false;
  if (window.location.hostname === 'localhost') {
    return console.log(`trackEvent ${eventName}`);
  }

  let useDefaultPageName = true;
  if (payload && payload.pageName) useDefaultPageName = false;

  window.analytics.track(eventName, {
    ...buildDefaultPayload(useDefaultPageName),
    ...payload
  });
};

export const trackOnVisit = (): void => {
  const pathName = window.location.pathname.split('/');
  const page = pathName[pathName.length - 1].replace('-', '_');
  let pageName;
  setContext({ pageName: page });
  switch (page) {
    case 'step_1':
      pageName = PAGES.step_1;
      break;
    case 'step_2':
      pageName = PAGES.step_2;
      break;
    case 'step_3':
      pageName = PAGES.step_3;
      break;
    case 'step_4':
      pageName = PAGES.step_4;
      break;
    case 'step_5':
      pageName = PAGES.step_5;
      break;
    case 'confirmation':
      pageName = PAGES.step_6;
      break;
    case 'unqualified':
      pageName = PAGES.unqualified;
      break;
    default:
      pageName = PAGES.unspecified;
      break;
  }
  trackPageView(pageName);
};

export const trackOnClick = (): void => {
  const flaggedElems: NodeListOf<Element> = document.querySelectorAll(
    '[data-track]'
  );
  flaggedElems.forEach((elem: HTMLElement): void => {
    const track: string = elem.dataset['track'] || '';
    elem.addEventListener('click', (): void => {
      trackEvent(`${track}_clicked`);
    });
  });
};

export const trackOnSelect = (value: Value, prefix?: string): void => {
  const values = (value as string).split(', ');
  let last_value = values[values.length - 1] as string;
  const customValues = Object.values(CUSTOM_VALUES);
  if (customValues.includes(last_value as CUSTOM_VALUES)) {
    const index = customValues.indexOf(last_value as CUSTOM_VALUES);
    last_value = Object.keys(CUSTOM_VALUES)[index];
  }
  if (last_value === '') last_value = 'blank';
  if (prefix) last_value = `${prefix}_${last_value}`;
  trackEvent(`${last_value}_selected`);
};

export const trackOnFocused = (): void => {
  const flaggedElems: NodeListOf<Element> = document.querySelectorAll(
    '[data-trackfocus]'
  );
  flaggedElems.forEach((elem: HTMLElement): void => {
    const track: string = elem.dataset['trackfocus'] || '';
    elem.addEventListener('focus', (): void => {
      trackEvent(`${track}_focused`);
    });
  });
};

export const trackRadio = (elem: HTMLInputElement, prefix: string): void => {
  trackOnSelect(elem.value, prefix);
};
