import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { css } from 'aphrodite';
import isNil from 'lodash-es/isNil';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';

import { ErrorType } from 'utils/error';
import GlobalStyles, {
  errorColor
} from 'components/common/styles/GlobalStyles';

import LinkButton from '../buttons/LinkButton';

export type StyleType = 'large' | 'small';

interface OwnProps {
  type: ErrorType;
  styleType: StyleType;
  navigateToLabel?: string;
  navigateTo?: string;
  callback?: () => void;
}

type Props = OwnProps & RouteComponentProps;

const ErrorPage: React.FC<Props> = ({
  type,
  callback,
  styleType,
  navigateTo,
  navigateToLabel
}: Props) => {
  let headerMessage = 'Oops! An error occured!';
  let subHeader = 'If problem still persists, contact support.';
  let statusCode = '';
  let error = '';

  switch (type) {
    case 'forbidden':
      headerMessage = "You're not permitted to access this page";
      subHeader = 'Sorry, contact system admin to give you permission';
      statusCode = '403';
      error = 'FORBIDDEN ERROR';
      break;
    case 'serverError':
      statusCode = '500';
      error = 'SERVER ERROR';
      break;
    case 'unknownError':
      statusCode = '520';
      error = 'UNKNOWN ERROR';
      break;
    case 'networkError':
      headerMessage = 'Network Error!';
      subHeader =
        "We're sorry. The error might be caused by a network connection failure or too many requests sent. Please try again after a few seconds.";
      statusCode = '429';
      error = 'CONNECTION ERROR';
      break;
    case 'pageNotFound':
      headerMessage = 'Page Not Found!';
      subHeader = "We're sorry, the page you requested could not be found.";
      statusCode = '404';
      error = 'PAGE NOT FOUND ERROR';
      break;
    default:
      statusCode = '404';
      error = 'NOT FOUND ERROR';
  }

  const mainContainer =
    styleType === 'large' ? css(GlobalStyles.whiteBackground) : '';

  const headerClass =
    styleType === 'large' ? css(GlobalStyles.errorHeader) : '';

  const subheaderClass = styleType === 'large' ? 'headline' : '';

  return (
    <div className={mainContainer}>
      <section className="cardSection _eventPrompt" data-testid="error-content">
        <FontAwesomeIcon
          icon={faUnlink}
          size={styleType === 'large' ? '6x' : '2x'}
          color={errorColor}
        />
        <h1 className={`${headerClass} _marginTop--normal`}>{headerMessage}</h1>
        <p className={`_marginBottom--small ${subheaderClass}`}>{subHeader}</p>
        <p className="_marginBottom--xxsmall -danger">
          {statusCode}: {error}
        </p>

        {!isNil(navigateTo) && !isNil(navigateToLabel) && (
          <div className="_marginTop--small">
            <LinkButton
              label={navigateToLabel}
              action={() => navigate(navigateTo)}
              dataTestId="navigate-to"
            />
          </div>
        )}

        {!isNil(callback) && (
          <LinkButton
            label="Try again"
            action={callback}
            dataTestId="try-again"
          />
        )}
      </section>
    </div>
  );
};

export default ErrorPage;
