import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  uploadButton: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 16px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: '#565EF0',
    ':hover': {
      backgroundColor: 'rgb(129 140 248)'
    },
    borderColor: 'red'
  },
  uploadLabel: {
    textOverflow: 'clip',
    border: 'solid 2px transparent',
    marginBottom: 0,
    fontSize: '0.95em',
    color: 'white',
    letterSpacing: '-0.028em'
  },
  clearButton: {
    marginLeft: 'auto',
    color: '#9ca3af',
    ':hover': {
      color: 'rgb(251 113 133)'
    }
  },
  multipleFilesMenu: {
    zIndex: 50,
    maxHeight: '240px',
    overflowY: 'auto',
    backgroundColor: 'rgb(243 244 246)',
    marginTop: '8px',
    marginLeft: '8px',
    position: 'absolute',
    borderRadius: '6px',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
  },
  caption: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    padding: '12px 16px',
    fontSize: '0.95em',
    color: '#414141',
    letterSpacing: '-0.028em'
  },
  uploadField: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingRight: '12px'
  },
  uploadFieldWrapper: {
    width: '100%',
    textAlign: 'left',
    position: 'absolute',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'none'
  },
  uploadFieldContainer: {
    position: 'relative',
    flexGrow: 1,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: 'rgb(249 250 251)',
    border: 'solid 2px #8082a9',
    borderLeft: 'none'
  },
  uploadButtonContainer: {
    position: 'relative',
    display: 'flex',
    borderRadius: '4px'
  },
  fileMenuItemClear: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'none'
  },
  fileMenuItem: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between'
  },
  fileGuide: {
    fontSize: '0.95em',
    color: '#414141',
    letterSpacing: '-0.028em'
  },
  errorColor: {
    color: '#da5e5e'
  }
});

export default styles;
