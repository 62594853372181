/* eslint-disable no-undef */
import Cookies from 'js-cookie';

export const clearCapitalAdvisorTokenCookie = () => {
  let domain: string;

  if (process.env.REACT_APP_SITE_ENV === 'development') {
    domain = 'localhost';
  } else {
    domain = '.firstcircle.ph';
  }

  Cookies.remove('capital_advisor_token', { domain });
};

export const setCapitalAdvisorTokenCookie = (token: string) => {
  let domain: string;

  if (process.env.REACT_APP_SITE_ENV === 'development') {
    domain = 'localhost';
  } else {
    domain = '.firstcircle.ph';
  }

  Cookies.set('capital_advisor_token', token, { domain });
};
