export const maskLocalMobileNumber = (mobileNumber: string) => {
  return mobileNumber.replace(/^(\d{4})(\d{3})(\d+)/, '($1) $2 $3');
};

export const maskMobileNumber = (string: string) => {
  let isLocal = true;
  let middleDigitsCount: number;

  if (string.slice(0, 3) === '+63' || string.slice(0, 2) === '09') {
    middleDigitsCount = 3;
  } else {
    isLocal = false;
    middleDigitsCount = string.length - 9;
  }

  const asterisks = asteriskSet(middleDigitsCount);

  return `${
    isLocal ? '(09**)' : `(${string.slice(0, 3)}**)`
  } ${asterisks} ${string.slice(-4)}`;
};

const asteriskSet = (num: number) => {
  const set = Array.from({ length: num }, (_, index) =>
    (index + 1) % 3 === 0 ? '* ' : '*'
  );
  return set.join('').trim();
};
