import ApplicationContext from 'contexts/ApplicationContext';
import React, { useContext } from 'react';
import { css } from 'aphrodite';

import Styles from 'components/templates/pageTemplate/OnboardingTemplateStyles';

interface OnboardingProgressBarProps {
  stepName?: string;
}
const OnboardingProgressBar: React.FC<OnboardingProgressBarProps> = ({
  stepName
}) => {
  const { stepNumber } = useContext(ApplicationContext);

  const multiplier = 25;
  const totalStepCount = 4;
  const percentage = `${stepNumber * multiplier}%`;

  return (
    <div className={`${css(Styles.progressContainer)}`}>
      <p className="_displayFlex -primary _justifyContent-center">
        <span className="byline _marginRight--normal">
          <strong>
            STEP {stepNumber} OF {totalStepCount}
          </strong>
        </span>
        <strong>{stepName}</strong>
      </p>
      <div
        className={`ds-percentageBar 
        ${css(Styles.barWrapper)}
        _marginBottom--normal _backgroundColor-pr300`}
      >
        <div className={`bar ${css(Styles.bar)}`} style={{ width: percentage }}>
          <p className={`_alignCenter ${css(Styles.percentageText)}`}>
            {percentage}
          </p>
        </div>
      </div>
    </div>
  );
};
export default OnboardingProgressBar;
