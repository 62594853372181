import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { isNil } from 'lodash-es';
import SyncLoader from 'react-spinners/SyncLoader';

interface Props {
  message?: string;
  color?: string;
  size?: number;
}

const Styles = StyleSheet.create({
  loaderWrapper: {
    height: '224px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loaderMessage: {
    marginTop: '24px',
    fontSize: '16px'
  }
});

const SimpleLoader: React.FC<Props> = ({ message, color, size }) => {
  return (
    <div className={`${css(Styles.loaderWrapper)}`} data-testid="simple-loader">
      <div>
        <SyncLoader color={color} size={size} />
      </div>
      {!isNil(message) && (
        <p className={`${css(Styles.loaderMessage)}`} style={{ color: color }}>
          {message || 'Loading Resources'}
        </p>
      )}
    </div>
  );
};

export default SimpleLoader;
