/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const DisqualificationPage: React.FC = () => {
  const qualifications = [
    { number: 1, text: 'Organization must not be a cooperative' },
    { number: 2, text: 'Business must be registered with BIR and DTI or SEC' },
    {
      number: 3,
      text: "Business must meet First Circle's revenue requirement"
    },
    {
      number: 4,
      text:
        'Business must be registered/operating within First Circle’s serviceable regions'
    }
  ];

  return (
    <div
      style={{ margin: '24px auto', maxWidth: '376px' }}
      data-testid="disqualification-page"
    >
      <div
        className="_marginBottom--large"
        style={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }}
      >
        Unfortunately we cannot on-board your business at this time for the
        following one or more reasons below:
      </div>

      <ol style={{ color: '#8082A9' }}>
        {qualifications.map(qualification => (
          <li
            key={qualification.number}
            style={{ listStyleType: 'decimal', lineHeight: '24px' }}
          >
            {qualification.text}
          </li>
        ))}
      </ol>

      <div
        className="_marginTop--large"
        style={{ lineHeight: '24px', color: '#8082A9' }}
      >
        If you have any questions or clarifications regarding our application
        form, please do not hesitate to reach out to
        <a
          href="mailto:support@firstcircle.com"
          data-testid="support-link"
          data-track="support"
          style={{ all: 'unset' }}
        >
          {' '}
          <u>support@firstcircle.com</u>
        </a>
      </div>
    </div>
  );
};

export default DisqualificationPage;
