import isNil from 'lodash/isNil';
import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';

import useFetch from '../useFetch';
import { getObID } from 'utils/localStorageSession';

export interface ActiveStorageBlob extends Blob {
  id: number;
  key: string;
  filename: string;
  content_type: string;
  byte_size: number;
  created_at: string;
}

interface FetchUploadedFilesReturn extends CommonReturnProps {
  fetchUploadedFiles: (fileCategory: 'documents' | 'identifications') => void;
}

const useFetchUploadedFiles = (
  props?: CommonProps
): FetchUploadedFilesReturn => {
  const leadId = getObID();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (response: any): void => {
    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback(response);
    }
  };

  const onFailCallback = (): void => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const fetchUploadedFiles = (
    fileCategory: 'documents' | 'identifications'
  ): void => {
    if (leadId) {
      executeRequest({
        path: `/leads/${leadId}/${fileCategory}`,
        method: METHODS.GET,
        shouldExecuteRequest: true
      });
    }
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    fetchUploadedFiles
  };
};

export default useFetchUploadedFiles;
