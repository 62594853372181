import React, { useContext } from 'react';
import { css } from 'aphrodite';

import { CommonModalProps } from 'interfaces/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ApplicationContext from 'contexts/ApplicationContext';
import { USER_TYPE } from 'interfaces/user';
import styles from './ModalStyles';

export interface OwnProps {
  businessDocuments: string;
}

type Props = OwnProps & CommonModalProps;

const BusinessDocumentsModal: React.FC<Props> = ({
  hideModal,
  businessDocuments
}) => {
  const { userType } = useContext(ApplicationContext);
  const withGIS = businessDocuments && businessDocuments === 'AFS & GIS';

  return (
    <div
      data-testid="business-document-modal"
      className={`${css(styles.modalWrapper)}`}
    >
      <div className={`${css(styles.modalContent)}`}>
        <div onClick={hideModal} className={`${css(styles.closeButton)}`}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
          Guide to uploading {userType === USER_TYPE.INBOUND ? 'your' : 'their'}{' '}
          {businessDocuments}
        </div>
        <div className={`${css(styles.body)}`}>
          <div style={{ marginTop: '24px' }}>
            General Submission Criteria:
            <ul className={`${css(styles.disc)}`}>
              <li className={`${css(styles.disc)}`}>
                Information must be clear and complete
              </li>
            </ul>
          </div>
          <div style={{ marginTop: '12px' }}>
            {withGIS ? 'AFS:' : 'AFS/ITR:'}
            <ul className={`${css(styles.disc)}`}>
              <li className={`${css(styles.disc)}`}>
                Must have a BIR {withGIS && 'or SEC '}acknowledgement receipt
              </li>
              <li className={`${css(styles.disc)}`}>
                Must be the latest AFS {!withGIS && 'or ITR'} submitted to BIR{' '}
                {withGIS && 'or SEC'}
              </li>
              <li className={`${css(styles.disc)}`}>
                Must belong to the company or sole proprietor
              </li>
            </ul>
          </div>
          {withGIS && (
            <div style={{ marginTop: '12px' }}>
              GIS:
              <ul className={`${css(styles.disc)}`}>
                <li className={`${css(styles.disc)}`}>
                  Must have an SEC acknowledgement receipt
                </li>
                <li className={`${css(styles.disc)}`}>
                  Must be the latest GIS submitted to SEC
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessDocumentsModal;
