// NOTE: The idea behing this is something similar to i18n.

/* eslint-disable @typescript-eslint/no-explicit-any */

export const LABELS: any = {
  capital_advisor: {
    step_1: {
      stepName: 'Lead Business Registration',
      headerTitle: "Tell us about the lead's business",
      business_name:
        "What is the exact business name written on the lead's DTI or SEC Certificate of Registration?",
      business_name_guide:
        "Please make sure this name is complete and identical to the name on the lead's Certificate of Registration.",
      business_reg_num: 'DTI or SEC Registration number',
      business_type: "What is the lead's business registered as?",
      business_name_placeholder: 'Enter full registered business name',
      business_reg_num_placeholder: 'Enter registration number',
      is_registered_w_operating_revenue:
        "Has the lead's business been registered with DTI or SEC and been operating with revenue for at least 6 months?",
      registration_region: "Which region is the lead's business registered in?",
      operation_region:
        "In what other region does the lead's business operate?",
      email: "Lead's Email Address",
      mobile_number: "Lead's Mobile Number"
    },
    step_2: {
      stepName: 'Lead Business Activity',
      headerTitle: "Tell us about the lead's business activity",
      industry: 'What industry is the lead mainly in?',
      clients: "Who are the lead's clients?",
      business_documents:
        'If the lead wants the fastest indication of what we can offer them, upload their most recent',
      business_documents_guide: 'Guide to uploading their',
      monthly_revenue: "What is the lead's estimated monthly revenue?",
      afs_upload_label: 'Upload their AFS (Optional)',
      itr_upload_label: 'Upload their ITR (Optional)',
      gis_upload_label: 'Upload their GIS (Optional)',
      unqualified: {
        button: "The Lead's business does not do general construction"
      }
    },
    step_3: {
      stepName: 'Lead Contact Information',
      headerTitle: "Tell us about the lead's contact information",
      business_relationship: 'What is their relationship with the business?',
      business_role: 'Please state their role in the business',
      first_name: "Lead's First Name",
      middle_name: "Lead's Middle Name (Please put NA if none)",
      last_name: "Lead's Last Name",
      mobile_number: "Lead's Mobile Number",
      work_number: "Lead's Work Number (Optional)",
      mobile_number_guide: 'We will send a 6-digit code to this number',
      birth_date: "Lead's Birthdate",
      email: "Lead's Email Address",
      id_upload_label: 'Upload their ID',
      id_upload_guide: 'Guide to uploading their ID'
    }
  },
  inbound: {
    step_1: {
      stepName: 'Business Registration',
      headerTitle: 'Tell us about your business',
      business_name:
        'What is the exact business name written on your DTI or SEC Certificate of Registration?',
      business_name_guide:
        'Please make sure this name is complete and identical to the name on your Certificate of Registration.',
      business_reg_num: 'DTI or SEC Registration number',
      business_type: 'What is your business registered as?',
      business_name_placeholder: 'Enter full registered business name',
      business_reg_num_placeholder: 'Enter registration number',
      is_registered_w_operating_revenue:
        'Has your business been registered with DTI or SEC and been operating with revenue for at least 6 months?',
      registration_region: 'Which region is your business registered in?',
      operation_region: 'In what other region does your business operate?',
      email: 'Email Address',
      mobile_number: 'Mobile Number'
    },
    step_2: {
      stepName: 'Business Activity',
      headerTitle: 'Tell us about your business activity',
      industry: 'What industry are you mainly in?',
      clients: 'Who are your clients?',
      business_documents:
        'If you want the fastest indication of what we can offer you, upload your most recent',
      business_documents_guide: 'Guide to uploading your',
      monthly_revenue: 'What is your estimated monthly revenue?',
      afs_upload_label: 'Upload your AFS (Optional)',
      itr_upload_label: 'Upload your ITR (Optional)',
      gis_upload_label: 'Upload your GIS (Optional)',
      unqualified: {
        button: 'My business does not do general construction'
      }
    },
    step_3: {
      stepName: 'Contact Information',
      headerTitle: 'Tell us about yourself',
      business_relationship: 'What is your relationship with the business?',
      business_role: 'Please state your role in the business',
      first_name: 'Your first name',
      middle_name: 'Your middle name (Please put NA if none)',
      last_name: 'Your last name',
      mobile_number: 'Your mobile number',
      work_number: 'Your work number (Optional)',
      mobile_number_guide: 'We will send a 6-digit code to this number',
      birth_date: 'Your Birthdate',
      email: 'Your email address',
      id_upload_label: 'Upload your ID',
      id_upload_guide: 'Guide to uploading your ID'
    }
  }
};
