/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext, useState } from 'react';
import { css } from 'aphrodite';
import { isNil } from 'lodash-es';

import { showNotification } from 'utils/toast';
import { buildValidationSchema } from 'validations/businessActivity';
import {
  industries,
  industriesWithSpecializedConstruction,
  monthlyRevenue
} from 'interfaces/onboarding';
import { navigateTo } from 'utils/navigation';
import { PAGES } from 'enums/analytics';
import { trackEvent, trackPageView } from 'utils/segmentTracker';
import { LABELS } from 'utils/labels';

import Styles from '../../templates/pageTemplate/OnboardingTemplateStyles';
import ApplicationContext from 'contexts/ApplicationContext';
import useForm from 'hooks/useForm';
import DynamicSelect, { Value } from 'components/common/selects/DynamicSelect';
import RaisedButton from 'components/common/buttons/RaisedButton';
import useSubmitBusinessActivity from 'hooks/onboarding/useSubmitBusinessActivity';
import { ServiceableRegions } from 'enums/region';
import ModalContext from 'contexts/ModalContext';
import { ModalComponent } from 'components/modals/ModalComponents';
import FileUpload from 'components/common/inputs/FileUpload';
import useUploadDocuments from 'hooks/onboarding/useUploadDocuments';
import useFetchUploadedFiles, {
  ActiveStorageBlob
} from 'hooks/onboarding/useFetchUploadedFiles';
import Checkbox from 'components/common/inputs/Checkbox';
import { CLIENTS } from 'enums/clients';
import BezierLoader from 'components/common/loaders/BezierLoader';

interface ResultType {
  afsDocs: { file: File; path: string }[];
  otherDocs: { file: File; path: string }[];
}

const BusinessActivityForm: React.FC = () => {
  const [uploadedDocuments, setUploadedDocuments] = useState<ResultType>({
    afsDocs: [],
    otherDocs: []
  });

  const [
    specializedConstructionVisible,
    setAddSpecializedConstruction
  ] = useState<boolean>(false);

  const {
    setContinueEnabled,
    setSubmitCurrentForm,
    submitCurrentForm,
    businessActivityValue,
    setQualified,
    userType,
    businessRegistrationInformationValue,
    isContinueLoading
  } = useContext(ApplicationContext);

  const {
    business_type,
    region_of_operation,
    region_of_registration
  } = businessRegistrationInformationValue;

  const isSoleProp =
    businessRegistrationInformationValue && business_type === 'single';

  const { setType, setModalProps } = useContext(ModalContext);

  const { handleSubmit, values, handleChange, handleSpecificChange } = useForm({
    defaultState: businessActivityValue,
    submitAction: submit,
    validationSchema: buildValidationSchema()
  });

  const notFilledOut = () => {
    // custom notFilledOut method from useForm

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { afs, itr, gis, ...fields } = values;

    return (
      Object.entries(fields).filter(([, v]) => {
        return isNil(v) || v === '' || v.length === 0;
      }).length !== 0
    );
  };

  function onAfsFileUpload(value: File | File[] | undefined | null) {
    handleSpecificChange({
      field: 'afs',
      value: value
    });
  }

  function onItrOrGisFileUpload(value: File | File[] | undefined | null) {
    handleSpecificChange({
      field: isSoleProp ? 'itr' : 'gis',
      value: value
    });
  }

  function isBusinessUnqualified(): boolean {
    if (!serviceable() && !highValueCustomer()) {
      return true;
    } else if (!qualifiedClients()) {
      return true;
    } else {
      return values.monthly_revenue === '₱400,000 or less';
    }
  }

  function highValueCustomer(): boolean {
    const HIGH_VALUE_REVENUE_RANGES = [
      '₱1,250,001 to ₱2,500,000',
      '₱2,500,001 to ₱4,000,000',
      '₱4,000,001 to ₱16,000,000',
      'More than ₱16,000,000'
    ];
    return HIGH_VALUE_REVENUE_RANGES.includes(values.monthly_revenue);
  }

  const qualifiedClients = () => {
    if (values.clients.length !== 1) return true;
    if (values.clients[0] !== CLIENTS.CONSUMERS) return true;

    const ACCEPTED_REVENUE_FOR_CONSUMER_CLIENTS = [
      '₱2,500,001 to ₱4,000,000',
      '₱4,000,001 to ₱16,000,000',
      'More than ₱16,000,000'
    ];

    return ACCEPTED_REVENUE_FOR_CONSUMER_CLIENTS.includes(
      values.monthly_revenue
    );
  };

  const serviceable = () => {
    if (region_of_operation && ServiceableRegions.includes(region_of_operation))
      return true;
    if (ServiceableRegions.includes(region_of_registration)) return true;

    return false;
  };

  function onSubmitSuccess(): void {
    if (isBusinessUnqualified()) {
      setQualified(false);
      navigateTo('/pre-qualification-form/unqualified');
    } else if (businessActivityValue.industry !== 'Construction') {
      navigateTo('/pre-qualification-form/step-3');
    }
  }

  function onSubmitFailed(): void {
    showNotification('error', 'Failed to submit business activity!');
  }

  function onUploadFailCallback() {
    showNotification('error', 'Failed to upload business documents!');
  }

  const { submitBusinessActivity } = useSubmitBusinessActivity({
    onSuccessCallback: onSubmitSuccess,
    onFailCallback: onSubmitFailed
  });

  const { uploadDocuments } = useUploadDocuments({
    onSuccessCallback: submitBusinessActivity,
    onFailCallback: onUploadFailCallback
  });

  function submit(): void {
    uploadDocuments(values);
  }

  // This is to avoid referencing setter
  // functions in useEffect call below
  // We don't want to monitor reference
  // changes in methods or functions
  const enableContinue = () => {
    const enabled = !notFilledOut() && values.industry !== 'Construction';
    setContinueEnabled(enabled);
  };

  useEffect(enableContinue, [notFilledOut, values.industry]);

  const handleFormSubmit = () => {
    if (submitCurrentForm) {
      const isConstruction = values.industry === 'Construction';
      handleSubmit(isConstruction);
    }
  };

  useEffect(handleFormSubmit, [submitCurrentForm]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFetchDocumentsSuccess = (response: any) => {
    const { afsDocs, otherDocs }: ResultType = response?.documents.reduce(
      (
        result: ResultType,
        {
          doc_type,
          path,
          blob
        }: { doc_type: string; path: string; blob: ActiveStorageBlob }
      ) => {
        const file = new File([blob], blob.filename, {
          type: blob.content_type
        });

        doc_type === 'Documents::AFS'
          ? result.afsDocs.push({ file, path })
          : result.otherDocs.push({ file, path });

        return result;
      },
      { afsDocs: [], otherDocs: [] }
    );

    setUploadedDocuments({ afsDocs, otherDocs });
    handleSpecificChange([
      { field: 'afs', value: afsDocs },
      { field: isSoleProp ? 'itr' : 'gis', value: otherDocs }
    ]);
  };

  const { fetchUploadedFiles } = useFetchUploadedFiles({
    onSuccessCallback: onFetchDocumentsSuccess
  });
  const fetchFiles = () => fetchUploadedFiles('documents');
  useEffect(fetchFiles, []);

  function onUnqualifiedClick(): void {
    trackEvent('not_general_construction_clicked');
    setAddSpecializedConstruction(true);
    setSubmitCurrentForm(false);
    handleSpecificChange({
      field: 'industry',
      value: 'Specialized Construction'
    });
    trackEvent('industry_specialized_construction');
  }

  function renderUnqualified(): React.ReactElement {
    trackPageView(PAGES.unqualified);

    return (
      <div
        className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal"
        data-testid="unqualifiedMessage"
      >
        <h1 className="_alignCenter _horizontalPadding--xlarge _marginTop--large -danger">
          Unfortunately, general construction companies do not qualify for
          financing with First Circle at the moment.
        </h1>
        <p className="_alignCenter _horizontalPadding--xlarge _marginTop--large _marginBottom--normal">
          General construction includes companies doing civil works, vertical
          construction, and manpower. If you think your company is not a general
          construction company, please click the button below.
        </p>

        <RaisedButton
          type="secondary"
          action={onUnqualifiedClick}
          dataTestId="specialized-button"
          classes="_outline"
          fullWidth
        >
          {LABELS[userType]['step_2'].unqualified.button}
        </RaisedButton>
      </div>
    );
  }

  const businessDocuments = () => {
    if (isSoleProp) {
      return 'AFS & ITR';
    }
    return 'AFS & GIS';
  };

  function renderInputs() {
    return (
      <div>
        <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--large">
          <label>{LABELS[userType]['step_2'].clients}</label>
          <small className="byline">Select all that apply.</small>
          <div className={css(Styles.clientsCheckbox)}>
            <Checkbox
              id="consumers-checkbox"
              name="clients"
              data-testid="consumers-checkbox"
              label={CLIENTS.CONSUMERS}
              onChange={handleChange}
              value={CLIENTS.CONSUMERS}
              checked={values.clients.includes(CLIENTS.CONSUMERS)}
              multi
              dataTrackCheck="clients_consumers"
            />
          </div>
          <div className={css(Styles.clientsCheckbox)}>
            <Checkbox
              id="businesses-checkbox"
              name="clients"
              data-testid="businesses-checkbox"
              label={CLIENTS.BUSINESSES}
              onChange={handleChange}
              value={CLIENTS.BUSINESSES}
              checked={values.clients.includes(CLIENTS.BUSINESSES)}
              multi
              dataTrackCheck="clients_businesses"
            />
          </div>
          <div className={css(Styles.clientsCheckbox)}>
            <Checkbox
              id="government-checkbox"
              name="clients"
              data-testid="government-checkbox"
              label={CLIENTS.GOVERNMENT}
              onChange={handleChange}
              value={CLIENTS.GOVERNMENT}
              checked={values.clients.includes(CLIENTS.GOVERNMENT)}
              multi
              dataTrackCheck="clients_government"
            />
          </div>
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--large">
          <label htmlFor="monthly-revenue">
            {LABELS[userType]['step_2'].monthly_revenue}
          </label>
          <p className="byline">
            {LABELS[userType]['step_2'].revenue_estimate_message}
          </p>
          <DynamicSelect
            name="monthly_revenue"
            defaultValue={values.monthly_revenue}
            options={monthlyRevenue}
            onChange={(value: Value) => {
              handleSpecificChange({ field: 'monthly_revenue', value: value });
            }}
            dataTestId="monthly-revenue-select"
            inputId="monthly-revenue"
            dataTrackSelect="monthly_revenue"
            placeholder="Select a range"
          />
        </div>
        <div className="_horizontalMargin--xxxlarge-tabletUp _verticalMargin--normal">
          <div>
            {`${
              LABELS[userType]['step_2'].business_documents
            } ${businessDocuments()}`}
          </div>
          <div
            onClick={() => {
              setType(ModalComponent.BUSINESS_DOCUMENTS);
              setModalProps({
                businessDocuments: businessDocuments()
              });
            }}
            className={`${css(Styles.businessDocumentsGuide)}`}
          >
            {`${
              LABELS[userType]['step_2'].business_documents_guide
            } ${businessDocuments()}`}
          </div>
        </div>

        <FileUpload
          label={LABELS[userType]['step_2'].afs_upload_label}
          onChange={onAfsFileUpload}
          hideGuide
          stagedFiles={uploadedDocuments?.afsDocs}
        />

        <FileUpload
          label={
            isSoleProp
              ? LABELS[userType]['step_2'].itr_upload_label
              : LABELS[userType]['step_2'].gis_upload_label
          }
          onChange={onItrOrGisFileUpload}
          hideGuide
          stagedFiles={uploadedDocuments?.otherDocs}
        />
      </div>
    );
  }

  const renderForm = () => {
    return (
      <div className="_marginTop--large" data-testid="business-activity-form">
        <div className="_horizontalMargin--xxxlarge-tabletUp _marginTop--large">
          <DynamicSelect
            name="industry"
            defaultValue={values.industry}
            options={
              specializedConstructionVisible
                ? industriesWithSpecializedConstruction
                : industries
            }
            onChange={(value: Value) => {
              handleSpecificChange({ field: 'industry', value: value });
              if (value === 'Construction') setSubmitCurrentForm(true);
            }}
            dataTrackSelect="industry"
            dataTestId="industry-select"
            inputId="industry"
            label={LABELS[userType]['step_2'].industry}
            placeholder="Select an industry"
          />
        </div>
        {values.industry === 'Construction'
          ? renderUnqualified()
          : renderInputs()}
      </div>
    );
  };

  return <>{isContinueLoading ? <BezierLoader /> : renderForm()}</>;
};

export default BusinessActivityForm;
