import { useEffect, useState } from 'react';

const useDesignSystemLoader = (callback: () => void) => {
  // eslint-disable-next-line no-undef
  const env = process.env.NODE_ENV;

  const [designSystemLoaded, setDesignSystemLoaded] = useState<boolean>(
    env === 'test' ? true : false
  );

  const entryPointElement = document.getElementsByClassName('ds-container');

  const appDelay = setInterval(function() {
    if (entryPointElement.length > 0) {
      const elementStyle = getComputedStyle(entryPointElement[0]);

      // Without DS, the position will be static as the default.
      // After DS gets loaded and ready, position will be relative as set in our DS.
      // This is enough to assume that the app already loaded DS.
      if (elementStyle.position === 'relative' && !designSystemLoaded) {
        setDesignSystemLoaded(true);
        clearInterval(appDelay);

        // Run caller callback
        callback();
      }
    }
  }, 500);

  // Load DS in head here programatically.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadDesignSystem = () => {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('crossOrigin', 'anonymous');
    linkElement.setAttribute(
      'href',
      'https://design.firstcircle.io/html/assets/main-dist.css'
    );
    document.head.appendChild(linkElement);
  };

  // Don't load DS when the env is test (Running our unit tests).
  const loadResources = () => {
    if (env !== 'test') {
      loadDesignSystem();
    } else {
      callback();
    }
  };

  // Load resources
  useEffect(loadResources, []);

  useEffect(() => {
    if (designSystemLoaded) clearInterval(appDelay);
  }, [designSystemLoaded, appDelay]);

  return {
    designSystemLoaded
  };
};

export default useDesignSystemLoader;
