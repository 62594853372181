export enum BusinessType {
  corporation = 'Corporation',
  single = 'Sole Proprietorship',
  partnership = 'Partnership',
  cooperative = 'Cooperative'
}

export const ServiceableBusinessTypes = [
  'corporation',
  'single',
  'partnership'
];
