import isNil from 'lodash/isNil';

import ApplicationContext from 'contexts/ApplicationContext';
import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { useContext } from 'react';
import { METHODS } from 'utils/api';
import { saveStep, saveUID } from 'utils/localStorageSession';
import useFetch from '../useFetch';
import setRumUser from 'utils/setRumUser';
import { isACapitalAdvisorUser, decodeToken, urlParams } from 'utils/url';
import {
  BusinessRegistrationInformation,
  UTMInformation
} from 'interfaces/onboarding';

interface BusinessRegistrationParams {
  name: string;
  reg_num: string;
  business_type: string;
  primary_region?: string;
  region: string;
  email_address: string;
  mobile_number: string;
}

interface SubmitBusinessRegistrationReturn extends CommonReturnProps {
  submitBusinessRegistration: (params: BusinessRegistrationParams) => void;
}

const useSubmitBusinessRegistration = (
  props?: CommonProps
): SubmitBusinessRegistrationReturn => {
  const {
    utmInformationValue,
    setLeadId,
    setPageHardRefreshed,
    setContinueIsLoading,
    resetContinueState,
    setBusinessRegistrationInformationValue
  } = useContext(ApplicationContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (response: any) => {
    setLeadId(response.data.id);
    saveUID(response.data.id);
    setPageHardRefreshed(false);
    setRumUser(response.data);
    resetContinueState();

    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback();
    }
  };

  const onFailCallback = (): void => {
    resetContinueState();

    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const submitBusinessRegistration = (params: BusinessRegistrationParams) => {
    const primary_region = isNil(params.primary_region)
      ? ''
      : params.primary_region;
    const urlParameters = urlParams();

    let payload: Partial<BusinessRegistrationInformation> &
      Partial<UTMInformation> = {
      ...utmInformationValue,
      ...params
    };

    if (!isNil(urlParameters) && isACapitalAdvisorUser()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken: any = decodeToken(
        urlParameters.capital_advisor_token
      );
      payload = { ...payload, agent_code: decodedToken.agent_code };
    }

    setBusinessRegistrationInformationValue({
      business_name: params.name,
      business_reg_num: params.reg_num,
      business_type: params.business_type,
      region_of_registration: params.region,
      region_of_operation: primary_region,
      email_address: params.email_address,
      mobile_number: params.mobile_number
    });

    saveStep(params, 'step_1');
    setContinueIsLoading(true);
    executeRequest({
      path: '/leads',
      method: METHODS.POST,
      shouldExecuteRequest: true,
      payload
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    submitBusinessRegistration
  };
};

export default useSubmitBusinessRegistration;
