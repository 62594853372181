import isNil from 'lodash/isNil';

import ApplicationContext from 'contexts/ApplicationContext';
import { CommonProps, CommonReturnProps } from 'interfaces/apiFetch';
import { useContext } from 'react';
import { METHODS } from 'utils/api';
import { saveStep } from 'utils/localStorageSession';
import { BusinessActivity } from 'interfaces/onboarding';

import useFetch from '../useFetch';

interface SubmitFinancingNeedReturn extends CommonReturnProps {
  submitBusinessActivity: (params: BusinessActivity) => void;
}

const useSubmitBusinessActivity = (
  props?: CommonProps
): SubmitFinancingNeedReturn => {
  const {
    leadId,
    setBusinessActivityValue,
    setContinueIsLoading,
    resetContinueState
  } = useContext(ApplicationContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (): void => {
    resetContinueState();

    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback();
    }
  };

  const onFailCallback = (): void => {
    resetContinueState();

    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const submitBusinessActivity = (params: BusinessActivity): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { files, ...rest } = params;
    saveStep(rest, 'step_2');
    setBusinessActivityValue(rest);
    setContinueIsLoading(true);
    executeRequest({
      path: `/leads/${leadId}`,
      method: METHODS.PATCH,
      shouldExecuteRequest: true,
      payload: { ...params, step: 'step_2' }
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    submitBusinessActivity
  };
};

export default useSubmitBusinessActivity;
