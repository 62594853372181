/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';
import { css } from 'aphrodite';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faTimesCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty, isNil } from 'lodash-es';
import styles from './FileUploadStyles';

type AllowedFileType = 'jpeg' | 'jpg' | 'pdf' | 'png';
const ALLOWED_FILE_TYPES = ['jpeg', 'jpg', 'pdf', 'png'];
const FILE_SIZE_LIMIT_IN_BYTES = 5242880;

interface FileUpload {
  path: string;
  file: File;
  type: AllowedFileType;
}

interface Props {
  label?: string;
  hideGuide?: boolean;
  onChange: (event: any) => void;
  stagedFiles?: { file: File; path: string }[];
}

const FileUpload: React.FC<Props> = ({
  label,
  hideGuide = false,
  onChange,
  stagedFiles
}) => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const accept = 'image/png,image/jpg,image/jpeg,application/pdf';
  const menuRef: any = useRef();
  const fileInputRef: any = useRef();

  useEffect(() => {
    let isUnmounted = false;

    return () => {
      isUnmounted = true;

      if (!isUnmounted && files?.length > 0) {
        files?.forEach((file: FileUpload) => {
          URL.revokeObjectURL(file.path);
        });
      }
    };
  }, [files]);

  useEffect(() => {
    if (files.length < 2) {
      setShowFiles(false);
    }
  }, [files]);

  // Click outside the dropdown files menu to close it
  useEffect(() => {
    const handleCloseMenu = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowFiles(false);
      }
    };

    document.addEventListener('mousedown', handleCloseMenu);

    return () => {
      document.removeEventListener('mousedown', handleCloseMenu);
    };
  }, []);

  // Re-stage previously uploaded files
  const reStageUploadedFiles = () => {
    if (isNil(stagedFiles) || isEmpty(stagedFiles)) return;

    let fileInstances: FileUpload[] = [...files];
    let fileType: string;

    stagedFiles?.map(({ file, path }: { file: any; path: string }) => {
      fileType = file.type.replace(/(.*)\//g, '');

      fileInstances = [
        ...fileInstances,
        {
          file,
          path: path ? path : URL.createObjectURL(file),
          type: fileType as AllowedFileType
        }
      ];
    });
    setFiles(fileInstances);
  };

  useEffect(reStageUploadedFiles, [stagedFiles]);

  const onClickButton = () => {
    fileInputRef.current.click();
  };

  const onPreviewClick = (file: FileUpload) => {
    window.open(file.path);
  };

  const onClickMenuButton = () => {
    if (files.length === 1) {
      onPreviewClick(files[0]);
    } else if (files.length > 1) {
      setShowFiles(true);
    }
  };

  const handlePreviewRemove = (file: FileUpload) => {
    URL.revokeObjectURL(file.path);
    const fileInstances = [...files].filter(
      (fileInstance: FileUpload) => file.path !== fileInstance.path
    );
    const formFiles = fileInstances.map((file: FileUpload) => file.file);

    setHasError(false);
    onChange(formFiles);
    setFiles(fileInstances);
  };

  const handleChange = (event: any) => {
    let fileInstances: FileUpload[] = [...files];
    let fileType: string;
    let fileSize: number;

    const isFileValid = (fileType: string, fileSize: number) => {
      if (!ALLOWED_FILE_TYPES.includes(fileType)) return false;
      if (fileSize > FILE_SIZE_LIMIT_IN_BYTES) return false;

      return true;
    };

    const inputFiles = Array.prototype.slice.call(event.target.files);

    inputFiles.forEach((file: any) => {
      fileType = file.type.replace(/(.*)\//g, '');
      fileSize = file.size;

      if (isFileValid(fileType, fileSize)) {
        fileInstances = [
          ...fileInstances,
          {
            file,
            path: URL.createObjectURL(file),
            type: fileType as AllowedFileType
          }
        ];
        setHasError(false);
      } else {
        setHasError(true);
      }
    });

    const formFiles = fileInstances.map((file: FileUpload) => file.file);

    // This allows this input file field to allow same file.
    event.target.value = null;
    onChange(formFiles);
    setFiles(fileInstances);
  };

  const renderInputCaption = () => {
    if (files.length === 0) return 'No files chosen';

    if (files.length === 1) {
      return files[0].file.name;
    } else {
      return `${files.length} Files Selected`;
    }
  };

  const renderFileAvatar = (file: FileUpload) => {
    return (
      <div key={file.path} className={`${css(styles.fileMenuItem)}`}>
        <div
          onClick={() => {
            onPreviewClick(file);
          }}
          style={{
            marginRight: '16px'
          }}
        >
          {file.file.name}
        </div>
        <button
          onClick={() => {
            handlePreviewRemove(file);
          }}
          className={`${css(styles.fileMenuItemClear)}`}
        >
          <FontAwesomeIcon
            icon={faTimes}
            size="1x"
            className={`${css(styles.clearButton)}`}
          />
        </button>
      </div>
    );
  };

  return (
    <div className="_horizontalMargin--xxxlarge-tabletUp _marginBottom--normal">
      {label && <div>{label}</div>}
      <div style={{ display: 'flex' }} className="_marginTop--xsmall">
        <div
          onClick={() => onClickButton()}
          className={`${css(styles.uploadButtonContainer)}`}
        >
          <input
            type="file"
            onChange={handleChange}
            accept={accept}
            ref={fileInputRef}
            style={{
              position: 'absolute',
              height: 0,
              width: 0,
              visibility: 'hidden'
            }}
            multiple
          />
          <label style={{ display: 'flex', marginBottom: 0 }}>
            <div className={`${css(styles.uploadButton)}`}>
              <span className={`${css(styles.uploadLabel)}`}>
                {files.length > 1 ? 'Choose files' : 'Choose file'}
              </span>
            </div>
          </label>
        </div>
        <Menu as="div" className={`${css(styles.uploadFieldContainer)}`}>
          <Menu.Button className={`${css(styles.uploadFieldWrapper)}`}>
            <div className={`${css(styles.uploadField)}`}>
              <div
                className={`${css(styles.caption)}`}
                onClick={() => onClickMenuButton()}
              >
                {renderInputCaption()}
              </div>

              {files.length > 1 ? (
                showFiles ? null : (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    size="1x"
                    onClick={() => setShowFiles(true)}
                  />
                )
              ) : (
                files.length > 0 && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="1x"
                    className={`${css(styles.clearButton)}`}
                    onClick={() => handlePreviewRemove(files[0])}
                  />
                )
              )}
            </div>
          </Menu.Button>

          {files.length > 1 && showFiles && (
            <div ref={menuRef} className={`${css(styles.multipleFilesMenu)}`}>
              {files.map((file: FileUpload) => {
                return renderFileAvatar(file);
              })}
            </div>
          )}
        </Menu>
      </div>
      <div
        className={`
          _marginTop--xsmall 
          ${css(styles.fileGuide)} 
          ${hasError ? `${css(styles.errorColor)}` : ''}
        `}
      >
        {hasError
          ? 'File must be less than 5MB and in PNG, JPG, or PDF format'
          : hideGuide
          ? ''
          : 'PNG, JPG or PDF'}
      </div>
    </div>
  );
};

export default FileUpload;
