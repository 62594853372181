/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { CommonAttributeProps, requiredStringField } from './common/fields';

const mobileError = `Invalid mobile number format.
  Follow this format: 09XXXXXXXXX`;

export const emailAddressField = ({ attribute }: CommonAttributeProps) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .email()
    .required()
    .label(attribute);

export const mobileNumberField = ({ attribute }: CommonAttributeProps) =>
  yup
    .number()
    .required()
    .typeError(mobileError)
    .test('is-valid', 'Minimum is eleven digits', function() {
      const options = this.options as any;
      const originalValue = options.originalValue;

      if (originalValue.length < 11) return false;
      return true;
    })
    .test('is-valid', mobileError, function() {
      const options = this.options as any;
      const originalValue = options.originalValue;
      if (
        originalValue.length === 11 &&
        originalValue.substring(0, 2) !== '09'
      ) {
        return false;
      }
      return true;
    })
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    first_name: requiredStringField({ attribute: 'First Name' }),
    mobile_number: mobileNumberField({ attribute: 'Mobile Number' }),
    email_address: emailAddressField({ attribute: 'Email Address' })
  };
  return yup.object().shape(omitBy(shape, isNil));
};
