import React from 'react';
import { css } from 'aphrodite';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GlobalStyles from 'components/common/styles/GlobalStyles';

type ButtonType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning';

export interface Props {
  type: ButtonType;
  micro?: boolean;
  children?: string;
  leftIconName?: string;
  rightIconName?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  dataTestId: string;
  dataTrack?: string;
  action: () => void;
  alignLeft?: boolean;
  isLoading?: boolean;
  classes?: string;
}

const RaisedButton: React.FC<Props> = (props: Props) => {
  const {
    type,
    micro,
    children,
    leftIconName,
    rightIconName,
    action,
    disabled,
    dataTestId,
    fullWidth,
    alignLeft,
    isLoading,
    dataTrack,
    classes
  } = props;

  const baseClass = `ds-btn ${classes}`;
  let buttonClass: string;

  switch (type) {
    case 'primary':
      buttonClass = `${baseClass} -primary`;
      break;
    case 'secondary':
      buttonClass = `${baseClass} -secondary`;
      break;
    case 'success':
      buttonClass = `${baseClass} -success`;
      break;
    case 'danger':
      buttonClass = `${baseClass} -danger`;
      break;
    case 'warning':
      buttonClass = `${baseClass} -warning`;
      break;
    default:
      buttonClass = `${baseClass} -primary`;
  }

  if (micro) {
    buttonClass = `${buttonClass} --micro`;
  }

  if (fullWidth) {
    buttonClass = `${buttonClass} ${css(GlobalStyles.fullWidth)}`;
  }

  if (alignLeft) {
    buttonClass = `${buttonClass} _alignLeft _justifyContent-flex-start`;
  }

  return (
    <button
      className={buttonClass}
      onClick={() => action()}
      disabled={disabled}
      data-testid={dataTestId}
      data-track={dataTrack}
    >
      {isLoading && (
        <span className="_marginRight--xxsmall" data-testid="spinner">
          <FontAwesomeIcon icon={faSpinner} size="sm" spin rotation={180} />
        </span>
      )}

      {leftIconName && (
        <i
          className={`materialIcon --small ${css(GlobalStyles.noMargin)}`}
          data-testid="left-icon"
        >
          {leftIconName}
        </i>
      )}
      <span className="buttonText">{children}</span>
      {rightIconName && (
        <i
          className={`materialIcon --small ${css(GlobalStyles.noMargin)}`}
          data-testid="right-icon"
        >
          {rightIconName}
        </i>
      )}
    </button>
  );
};

export default RaisedButton;
