/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export interface Props {
  showHeader?: boolean;
  showLogin?: boolean;
}

const OnboardingHeader: React.FC<Props> = ({ showHeader, showLogin }) => {
  return (
    <>
      {showHeader && (
        <h2 className="headline _alignCenter -darkBlue">
          Find out if you're eligible for a business loan in 3 minutes!
        </h2>
      )}
      {showLogin && (
        <p className="_alignCenter byline _margin--large">
          Already have an account?
          <a
            className="_marginLeft--xxsmall"
            // eslint-disable-next-line no-undef
            href={`${process.env.REACT_APP_FCA_URL}/login`}
            data-testid="login-link"
            data-track="log_in_step1"
          >
            Log in.
          </a>
        </p>
      )}
    </>
  );
};
export default OnboardingHeader;
